import { Box, Flex, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import DeleteIcon from '../../assets/images/svgs/DeleteIcon.svg';
import EditIcon from '../../assets/images/svgs/EditIcon.svg';
import ThreeDotss from '../../assets/images/svgs/ThreeDotss.svg';

interface ThreeDotsProps {
  holder: any;
  onEdit: (record: any) => void;
  onDelete: (record: any) => void;
  onSaveChanges: (updatedRecord: any) => Promise<void>;
  onConfirmDelete: () => Promise<void>;
}

const ThreeDots: React.FC<ThreeDotsProps> = ({ holder, onEdit, onDelete, onSaveChanges, onConfirmDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        buttonRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleEdit = () => {
    onEdit(holder);
    setIsOpen(false);
  };

  const handleDelete = () => {
    onDelete(holder);
    setIsOpen(false);
  };

  return (
    <Box position="relative">
      <Box
        ref={buttonRef}
        display="flex"
        padding="6px"
        alignItems="center"
        gap="10px"
        borderRadius="12px"
        transition="all 0.2s ease"
        background={isOpen ? 'var(--Neutrals-2, #F6F6F6)' : 'transparent'}
        onClick={() => setIsOpen(!isOpen)}
        _hover={{
          background: 'var(--Neutrals-2, #F6F6F6)',
          cursor: 'pointer',
        }}
        _active={{
          background: 'var(--Neutrals-2, #F6F6F6)',
        }}
      >
        <Image src={ThreeDotss} alt="threeDots" />
      </Box>

      {isOpen && (
        <Box ref={menuRef} position="absolute" right="0" top="100%" marginTop="8px" zIndex={1000}>
          <Flex
            display="flex"
            padding="12px 24px"
            alignItems="center"
            gap="12px"
            borderRadius="12px"
            justifyContent="center"
            border="0.5px solid rgba(0, 0, 0, 0.10)"
            background="#FFF"
            width={'max-content'}
            boxShadow="0px 1px 35px 0px rgba(0, 0, 0, 0.15)"
          >
            <Flex display="flex" alignItems="center" gap="6px" cursor="pointer" onClick={handleEdit}>
              <Image src={EditIcon} alt="edit" />
              <Text
                color="var(--Text-action, #4096FF)"
                fontFamily="Poppins"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="22px"
              >
                Edit
              </Text>
            </Flex>

            <div
              style={{
                width: '1px',
                height: '22px',
                background: 'rgba(0, 0, 0, 0.05)',
                display: 'flex',
                alignItems: 'center',
              }}
            />

            <Flex display="flex" alignItems="center" gap="6px" cursor="pointer" onClick={handleDelete}>
              <Image src={DeleteIcon} alt="delete" />
              <Text
                color="#FF4D4F"
                fontFamily="Poppins"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="22px"
              >
                Delete
              </Text>
            </Flex>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default ThreeDots;
