import { Badge, Box, Flex, Image, Input, InputGroup, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { gsap } from 'gsap';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../../api/axios';
import { Content_HeaderTL } from '../../assets/animation/Content_Header';
import CoveyInsightMobileLogo from '../../assets/images/svgs/coveyInsightMobileLogo.svg';
import HamBurgerMenuIconMobile from '../../assets/images/svgs/hamBurgerMenuIconMobile.svg';
import headerCalender from '../../assets/images/svgs/headerCalender.svg';

import profileImage from '../../assets/images/svgs/profileImage.svg';
import SearchIcon from '../../assets/images/svgs/SearchIcon.svg';
import { Account } from '../../Icons/AccountIcon';
import { SidebarProps } from '../../interfaces';
import OverallSearchModal from '../modals/OverallSearchModal';
import NotificationPanel from './Notification';

interface DropDownMenuProps {
  mobileView?: boolean;
}

interface UserData {
  username: string;
}

interface MenuOptions {
  key: string;
  text: string;
}

interface Notification {
  id: string;
  category: string;
  category_name: string;
  title: string;
  message: string;
  priority: string;
  status: string;
  created_at: string;
  read_at: string | null;
  data: any;
  related_object?: {
    type: string;
    id: string;
    str: string;
  };
}

interface NotificationCount {
  total_unread: number;
  by_category: Record<string, number>;
}

const DropDownMenu = ({ mobileView }: DropDownMenuProps) => {
  const [username, setUsername] = useState('Degen');
  const menuOptions: MenuOptions[] = [
    {
      key: 'dashboard',
      text: 'Dashboard',
    },
    {
      key: 'portfolio',
      text: 'Portfolio',
    },
    {
      key: 'marketOverview',
      text: 'Market Overview',
    },
    {
      key: 'wallet',
      text: 'Wallet',
    },
    {
      key: 'transactions',
      text: 'Transactions',
    },
    {
      key: 'reports',
      text: 'Reports',
    },
    {
      key: 'insight',
      text: 'Insight',
    },
  ];

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get<{ data: UserData }>('api/user/current/');
        setUsername(response.data.data.username);
      } catch (error) {
        console.error('Error fetching username:', error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          {mobileView ? (
            <MenuButton
              h={['30px', '30px', '44px', '44px', '44px']}
              w={['30px', '30px', '44px', '44px', '44px']}
              borderRadius="50%"
              backgroundColor="#353535"
              display={['flex', 'flex', 'none']}
              position="relative"
            >
              <Account position="absolute" top="3px" right={'-17px'} />
            </MenuButton>
          ) : (
            <MenuButton
              display={['none', 'none', 'block', 'flex', 'flex']}
              borderLeft="1px solid rgba(0, 0, 0, 0.05)"
              fontSize="16px"
              fontStyle={'normal'}
              width={'fit-content'}
              fontWeight="500px"
              color="#909090"
              flexDirection={'row'}
              height={'100%'}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px', paddingLeft: '12px' }}>
                <Image src={profileImage} width={'44px'} height={'44px'} />
                <Text
                  color="var(--Text-Primary-default, #000)"
                  fontFamily="Poppins"
                  fontSize="18px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="38px"
                >
                  {username}
                </Text>
                {/* <Image src={ArrowDown} width={'21px'} height={'21px'} /> */}
              </div>
            </MenuButton>
          )}
          {/* <MenuList bg="#353535" borderRadius="10px">
            {menuOptions.map((sideBarOption: MenuOptions) => (
              <MenuItem
                key={sideBarOption.key}
                _hover={{
                  bg: '#494949',
                  color: 'white',
                }}
                color="#909090"
                zIndex={1}
              >
                {sideBarOption?.text}
              </MenuItem>
            ))}
          </MenuList> */}
        </>
      )}
    </Menu>
  );
};

const NotificationMenu = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationCount, setNotificationCount] = useState<NotificationCount | null>(null);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get('api/user/notifications/');
      setNotifications(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const fetchNotificationCount = async () => {
    try {
      const response = await axios.get('api/user/notifications/count/');
      setNotificationCount(response.data);
    } catch (error) {
      console.error('Error fetching notification count:', error);
    }
  };

  const markNotificationsAsRead = async () => {
    try {
      const unreadIds = notifications.filter((n) => n.status === 'UNREAD').map((n) => n.id);

      if (unreadIds.length > 0) {
        await axios.post('api/user/notifications/mark-read/', {
          notification_ids: unreadIds,
        });
        fetchNotificationCount();
      }
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  useEffect(() => {
    fetchNotificationCount();
  }, []);

  return (
    <Menu
      onOpen={() => {
        fetchNotifications();
        markNotificationsAsRead();
      }}
    >
      <Box position="relative">
        <MenuButton
          display="flex"
          padding="10px"
          alignItems="center"
          gap="10px"
          borderRadius="100px"
          background="var(--Neutrals-2, #F6F6F6)"
        >
          {/* <Image src={NotificationIcon} width={'24px'} height={'24px'} /> */}
          {notificationCount && notificationCount.total_unread > 0 && (
            <Badge
              position="absolute"
              top="-5px"
              right="-5px"
              borderRadius="full"
              bg="red.500"
              color="white"
              fontSize="xs"
            >
              {notificationCount.total_unread}
            </Badge>
          )}
        </MenuButton>
      </Box>
      <MenuList
        bg="white"
        borderRadius="10px"
        boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
        maxHeight="400px"
        overflowY="auto"
        padding="12px"
        minWidth="320px"
      >
        {notifications.length === 0 ? (
          <Text p={4} color="gray.500">
            No notifications
          </Text>
        ) : (
          notifications.map((notification) => (
            <Box
              key={notification.id}
              p={3}
              borderBottom="1px solid"
              borderColor="gray.100"
              _hover={{ bg: 'gray.50' }}
              position="relative"
            >
              <Badge
                position="absolute"
                top={2}
                right={2}
                colorScheme={notification.status === 'UNREAD' ? 'blue' : 'gray'}
                fontSize="xs"
              >
                {notification.category_name}
              </Badge>

              <Text fontWeight="bold" fontSize="sm" mb={1}>
                {notification.title}
              </Text>

              <Text fontSize="sm" color="gray.600">
                {notification.message}
              </Text>

              {notification.related_object && (
                <Text fontSize="xs" color="blue.500" mt={1}>
                  Related: {notification.related_object.str}
                </Text>
              )}

              <Flex justify="space-between" align="center" mt={2}>
                <Text fontSize="xs" color="gray.400">
                  {new Date(notification.created_at).toLocaleString()}
                </Text>

                {notification.priority && (
                  <Badge
                    colorScheme={
                      notification.priority === 'HIGH' ? 'red' : notification.priority === 'MEDIUM' ? 'yellow' : 'green'
                    }
                    fontSize="xs"
                  >
                    {notification.priority}
                  </Badge>
                )}
              </Flex>
            </Box>
          ))
        )}
      </MenuList>
    </Menu>
  );
};

function Header({ sideBarOpen, setSideBarOpen }: SidebarProps) {
  const location = useLocation();
  const Content_HeaderRef = useRef<HTMLDivElement>(null);
  const [isTokenHolderPage, setIsTokenHolderPage] = useState(false);
  const { pathname } = location;
  const currentDate = format(new Date(), 'EEEE, dd MMMM');
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      Content_HeaderTL(Content_HeaderRef.current);
    }, Content_HeaderRef);

    return () => ctx.revert();
  }, [Content_HeaderRef]);

  useEffect(() => {
    if (pathname === '/tokenHolders') {
      setIsTokenHolderPage(true);
    }
  }, [pathname]);

  return (
    <Box
      padding={'12px 24px'}
      minW={['unset', 'unset', 'unset', 'unset', '1070px']}
      // height={['44px']}
      w={['100%']}
      borderBottom="1px solid rgba(242, 243, 245, 0.14)"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      ref={Content_HeaderRef}
      zIndex={1}
      className="content-header"
      background={'#FFF'}
      boxShadow={'0px 1px 6px 0px rgba(0, 0, 0, 0.12)'}
    >
      <Box className="gsap-item-left" display="flex" alignItems="center" gap={['8px', '16px', '16px', '16px', '16px']}>
        <Box
          h={['30px', '44px', '44px', '44px', '44px']}
          w={['30px', '44px', '44px', '44px', '44px']}
          borderRadius="50%"
          backgroundColor="#FFFFFF"
          display={['flex', 'flex', 'none', 'none', 'none']}
          justifyContent="center"
          alignItems="center"
          onClick={() => setSideBarOpen(!sideBarOpen)}
          cursor="pointer"
        >
          <Image
            h={['22px', '22px', '44px', '44px', '44px']}
            w={['22px', '22px', '44px', '44px', '44px']}
            src={HamBurgerMenuIconMobile}
            alt="logo"
          />
        </Box>
        <Image
          h={['64px', '64px', '0', '0', '0']}
          w={['64px', '64px', '0', '0', '0']}
          src={CoveyInsightMobileLogo}
          alt="logo"
        />
        <Box
          display="flex"
          padding="10px"
          alignItems="center"
          gap="10px"
          borderRadius="100px"
          background="var(--Neutrals-2, #F6F6F6)"
        >
          <Image
            h={['0', '0', '44px', '24px', '24px']}
            w={['0', '0', '44px', '24px', '24px']}
            src={headerCalender}
            alt="logo"
          />
        </Box>
        <Text
          color="var(--Text-Primary-default, #000)"
          textAlign="center"
          fontFamily="Poppins"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="28px"
        >
          {currentDate}
        </Text>

        {/* {isTokenHolderPage && (
          <>
            <Box borderLeft={'1px solid #000000'} height="32px"></Box>
            <Box>
              <Text fontSize={'20px'} fontWeight="700" color="black">
                Token Holders
              </Text>
            </Box>
          </>
        )} */}
      </Box>
      <Flex width="fit-content" justify="space-between" align="center">
        <InputGroup
          cursor="pointer"
          width="fit-content"
          padding="0px 20px 0px 12px"
          gap="10px"
          border="0.5px solid var(--Neutrals-4, #E9E9E9)"
          borderRadius="16px"
          background="var(--Neutrals-2, #F6F6F6)"
          alignItems="center"
          onClick={() => setIsSearchModalOpen(true)}
        >
          <Image onClick={() => setIsSearchModalOpen(true)} src={SearchIcon} alt="search" cursor="pointer" />
          <Input
            readOnly
            onClick={(e) => {
              e.preventDefault();
              setIsSearchModalOpen(true);
            }}
            border="none"
            color={'#000'}
            padding={0}
            placeholder="Search by address or tag"
            background="transparent"
            _placeholder={{
              color: 'var(--Text-Primary-description, #909090)',
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontWeight: '500',
            }}
            _focus={{
              border: 'none',
              boxShadow: 'none',
            }}
          />
        </InputGroup>
      </Flex>
      <Box
        className="gsap-item-right"
        display="flex"
        // h="280px"
        alignItems="center"
        gap={['8px', '16px', '16px', '12px', '12px']}
      >
        {/* <div className="gsap-item-inner"> */}
        {/* <NotificationMenu /> */}
        <NotificationPanel />
        <DropDownMenu />
        <DropDownMenu mobileView={true} />
        {/* </div> */}
      </Box>
      {isSearchModalOpen && <OverallSearchModal onClose={() => setIsSearchModalOpen(false)} />}
    </Box>
  );
}

export default Header;
