import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { default as React, useEffect, useState } from 'react';
import axios from '../api/axios';
import walletGreen from '../assets/images/svgs/walletGreen.svg';
import walletRed from '../assets/images/svgs/walletRed.svg';
import CircularProgress from '../components/CircularProgress';
import BiggestBS from '../components/common/BiggestBS';
import DatePicker from '../components/common/DatePicker';
import DaysFilter from '../components/common/DaysFilter';
import SendReceiveBlock from '../components/common/SendReceiveBlock';
import Text from '../components/common/Text';
import TransferTypeFilter from '../components/common/TransferTypeFilter';
import { BalanceFilter } from '../Icons/BalanceFilter';
import { BalanceIcon } from '../Icons/BalanceIcon';
import { InfoCircle } from '../Icons/InfoCircle';
import { useLoginStore } from '../store';
import { getExplorerUrl, openInNewTab } from '../utils/walletUtils/walletutils';
import TablePagination from './TablePagination';

interface HolderResponse {
  id: number;
  wallet_address: string;
  token_balance: string;
  rank: number;
  holder_name: string;
  share_percentage: string;
  address_book_entry: {
    id: number;
    wallet_address: string;
    name: string;
    created_at: string;
    updated_at: string;
  };
}

interface HoldersDataResponse {
  holders: HolderResponse[];
  total_balance: string;
}

interface BiggestBSData {
  Name: string;
  WalletImage: string;
  Date: string;
  TotalAmount: string;
  title: string;
  color: string;
  TxHash: string;
}

interface Holder {
  id: number;
  name: string;
  address: string;
  share: string;
}

const tableData = {
  totalValue: '134K ($DRINK)',
};

interface TransactionResponse {
  id: number;
  transaction_value_usd: string;
  block_time: string;
  transaction_from: string;
  transaction_to: string;
  transfer_type: string;

  transaction_hash: string;

  address_book_entry: {
    wallet_address: string;
    name: string;
  };
}

interface LatestTransactionResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    time_range: string;
    total_count: number;
    transactions: {
      address: string;
      address_book_name: string;
      amount: string;
      block_time: string;
      transaction_hash: string;
      transfer_type: 'Receive' | 'Send';
    }[];
  };
}

interface ApiTransactionResponse {
  transactions: {
    biggest_receive: TransactionResponse;
    biggest_send: TransactionResponse;
  };
  time_range: string;
}

const AddressBooks = () => {
  const defaultBiggestTransactions: BiggestBSData[] = [
    {
      color: 'var(--red-1, #FFF1F0)',
      Name: '-',
      WalletImage: walletGreen,
      Date: '-',
      TotalAmount: '$0',
      title: 'Biggest Receive',
      TxHash: '',
    },
    {
      color: 'var(--green-1, #EFFBF5)',
      Name: '-',
      WalletImage: walletRed,
      Date: '-',
      TotalAmount: '$0',
      title: 'Biggest Send',
      TxHash: '',
    },
  ];

  const [biggestTransactions, setBiggestTransactions] = useState<BiggestBSData[]>(defaultBiggestTransactions);
  const [timeRanges, setTimeRanges] = useState({
    receive: 'all',
    send: 'all',
  });

  const [transferTypeFilter, setTransferTypeFilter] = useState('all');
  const [isLoadingHolders, setIsLoadingHolders] = useState(false);
  const [validDates, setValidDates] = useState<string[]>([]);
  const [isLoadingLatest, setIsLoadingLatest] = useState(false);
  const [topHoldersTimeRange, setTopHoldersTimeRange] = useState('all');
  const [biggestTxTimeRange, setBiggestTxTimeRange] = useState('all');
  const [latestTxTimeRange, setLatestTxTimeRange] = useState('all');
  const [holdersData, setHoldersData] = useState<HolderResponse[]>([]);
  const [totalBalance, setTotalBalance] = useState('');
  const [amountSort, setAmountSort] = useState<'none' | 'asc' | 'desc'>('none');
  const [dateSort, setDateSort] = useState<'none' | 'asc' | 'desc'>('desc');

  const [latestTransactions, setLatestTransactions] = useState<LatestTransactionResponse['results']['transactions']>(
    [],
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const chainId = useLoginStore((state: any) => state.userData.blockchain_network.chain_id);

  const formatBalance = (balance: string) => {
    try {
      const num = parseFloat(balance);
      return num.toFixed(2);
    } catch (error) {
      console.error('Error formatting balance:', error);
      return '0.00';
    }
  };

  useEffect(() => {
    console.log('chainId updated:', chainId);
  }, [chainId]);

  const handleAmountSort = () => {
    const nextSort = amountSort === 'none' ? 'asc' : amountSort === 'asc' ? 'desc' : 'none';
    setAmountSort(nextSort);

    setLatestTransactions((prev) => {
      return [...prev].sort((a, b) => {
        const amountA = Number(a.amount);
        const amountB = Number(b.amount);

        if (nextSort === 'asc') {
          return amountA - amountB;
        } else if (nextSort === 'desc') {
          return amountB - amountA;
        } else {
          return 0;
        }
      });
    });
  };

  const handleDateSort = () => {
    const nextSort = dateSort === 'none' ? 'asc' : dateSort === 'asc' ? 'desc' : 'none';
    setDateSort(nextSort);

    setLatestTransactions((prev) => {
      return [...prev].sort((a, b) => {
        const dateA = new Date(a.block_time).getTime();
        const dateB = new Date(b.block_time).getTime();

        if (nextSort === 'asc') {
          return dateA - dateB;
        } else if (nextSort === 'desc') {
          return dateB - dateA;
        } else {
          return 0;
        }
      });
    });
  };

  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Formats a date string into a human-readable format with the following structure:
   * `YYYY-MM-DD HH:MM AM/PM`
   *
   * @param {string} dateString - The date string to format
   * @returns {string} Formatted date string
   */
  /******  0398929d-4bc1-4e72-a422-f3ecec0e19cb  *******/
  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      const formattedTime = date.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(
        2,
        '0',
      )} ${formattedTime}`;
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  };

  const formatWalletAddress = (address: string) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const formatAmount = (amount: string) => {
    return `${Number(amount).toLocaleString()}`;
  };

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const fetchHolders = async (date?: Date) => {
    setIsLoadingHolders(true);
    try {
      let url = '/api/user/address-book/top/';
      if (date) {
        const formattedDate = date.toLocaleDateString('en-CA');
        url += `?date=${formattedDate}`;
      }

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });

      const { entries, total_balance, valid_dates } = response.data; // Assuming API returns valid_dates

      if (Array.isArray(entries)) {
        setHoldersData(entries);
        setTotalBalance(total_balance);
      }

      if (Array.isArray(valid_dates)) {
        setValidDates(valid_dates);
      }
    } catch (error) {
      console.error('Error fetching holders:', error);
      setHoldersData([]);
      setTotalBalance('0');
      setValidDates([]);
    } finally {
      setIsLoadingHolders(false);
    }
  };
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      fetchHolders(date);
    } else {
      fetchHolders();
    }
  };

  const fetchBiggestTransactions = async () => {
    try {
      const [receiveResponse, sendResponse] = await Promise.all([
        axios.get<ApiTransactionResponse>(`/api/user/address-book/top/transactions/?time_range=${timeRanges.receive}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        }),
        axios.get<ApiTransactionResponse>(`/api/user/address-book/top/transactions/?time_range=${timeRanges.send}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        }),
      ]);

      const formattedTransactions: BiggestBSData[] = [];

      // Format receive transaction
      const receiveData = receiveResponse.data.transactions.biggest_receive;
      formattedTransactions.push({
        color: 'var(--green-1, #EFFBF5)',
        Name: receiveData?.address_book_entry?.name,
        WalletImage: walletGreen,
        Date: receiveData ? formatDate(receiveData.block_time) : '-',
        TotalAmount: receiveData ? formatAmount(receiveData.transaction_value_usd) : '0',
        title: 'Biggest Receive',
        TxHash: receiveData ? receiveData.transaction_hash : '',
      });

      // Format send transaction
      const sendData = sendResponse.data.transactions.biggest_send;
      formattedTransactions.push({
        color: 'var(--red-1, #FFF1F0)',
        Name: sendData?.address_book_entry.name,
        WalletImage: walletRed,
        Date: sendData ? formatDate(sendData.block_time) : '-',
        TotalAmount: sendData ? formatAmount(sendData.transaction_value_usd) : '0',
        title: 'Biggest Send',
        TxHash: sendData ? sendData.transaction_hash : '',
      });

      setBiggestTransactions(formattedTransactions);
    } catch (error) {
      console.error('Error fetching biggest transactions:', error);
      setBiggestTransactions(defaultBiggestTransactions);
    }
  };

  // Update the useEffect for biggestTransactions
  useEffect(() => {
    fetchBiggestTransactions();
  }, [timeRanges.receive, timeRanges.send]);

  // API Handlers for Latest Transactions
  const fetchLatestTransactions = async (page = 1) => {
    setIsLoadingLatest(true);
    try {
      let url = `/api/user/address-book/latest-transactions/?page=${page}&time_range=${latestTxTimeRange}`;
      if (transferTypeFilter !== 'all') {
        url += `&transfer_type=${transferTypeFilter}`;
      }
      const response = await axios.get<LatestTransactionResponse>(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });
      if (response.data && response.data.results) {
        const transactions = response.data.results.transactions;
        if (transferTypeFilter !== 'all') {
          const filteredTransactions = transactions.filter((tx) => tx.transfer_type === transferTypeFilter);
          setLatestTransactions(filteredTransactions);
        } else {
          setLatestTransactions(transactions);
        }
        setTotalCount(response.data.count);
        setHasNext(!!response.data.next);
        setHasPrevious(!!response.data.previous);
      }
    } catch (error) {
      console.error('Error fetching latest transactions:', error);
      setLatestTransactions([]);
      setTotalCount(0);
      setHasNext(false);
      setHasPrevious(false);
    } finally {
      setIsLoadingLatest(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchLatestTransactions(newPage);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    fetchHolders();
  }, [topHoldersTimeRange]);

  useEffect(() => {
    fetchBiggestTransactions();
  }, [biggestTxTimeRange]);

  useEffect(() => {
    fetchLatestTransactions(1);
  }, [latestTxTimeRange, transferTypeFilter, currentPage]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '36px',
      }}
    >
      {/* Main Text */}
      <div
        style={{
          color: 'var(--Text-Primary-default, #000)',
          fontFamily: 'Poppins, sans-serif',
          fontSize: '30px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '38px',
        }}
      >
        Address Book Dashboard
      </div>

      {/* 2nd Main Box */}
      <Box display="flex" alignItems="center" gap="32px">
        <Box
          display="flex"
          padding="24px"
          flexDirection="column"
          alignItems="center"
          gap="24px"
          flex="1 0 0"
          alignSelf="stretch"
          borderRadius="36px"
          border="1px solid rgba(255, 255, 255, 0.10)"
          background="var(--Neutrals-1, #FFF)"
          boxShadow="0px 1px 7px 0px rgba(0, 0, 0, 0.08)"
        >
          <Box display={'flex'} justifyContent="space-between" width="100%">
            <Box
              display="flex"
              alignItems="center"
              gap="6px"
              alignSelf="stretch"
              color="var(--Text-Primary-default, #000)"
            >
              <InfoCircle boxSize="24px" />
              <div style={{ fontFamily: 'Poppins', fontSize: '20px', fontWeight: '700', lineHeight: '28px' }}>
                Top 10 Holders
              </div>
            </Box>
            {/* <DaysFilter
              onTimeRangeChange={(range) => {
                setTopHoldersTimeRange(range);
              }}
              activeTimeRange={topHoldersTimeRange}
            /> */}
            <DatePicker onDateChange={handleDateChange} selectedDate={selectedDate} validDates={validDates} />
          </Box>

          {/* Table */}
          <Table variant="unstyled" display={['none', 'none', 'none', 'table']}>
            <Thead display={'block'}>
              <Tr borderRadius={'10px'} background={'#F6F6F6'} display="flex" justifyContent="space-between">
                <Th
                  border="none"
                  color="var(--Text-Primary-description, #909090)"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="22px"
                  padding={'12px 14px'}
                >
                  #
                </Th>
                <Th
                  border="none"
                  color="var(--Text-Primary-description, #909090)"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="22px"
                  padding={'12px 14px'}
                  flex={'0.5'}
                  display={'flex'}
                  alignItems={'flex-start'}
                >
                  Holder
                </Th>
                <Th
                  border="none"
                  color="var(--Text-Primary-description, #909090)"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="22px"
                  padding={'12px 14px'}
                  flex={'1'}
                  display={'flex'}
                  alignItems={'flex-start'}
                >
                  Balance
                </Th>
                <Th
                  border="none"
                  color="var(--Text-Primary-description, #909090)"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="22px"
                  padding={'12px 14px'}
                  flex="1"
                >
                  Address
                </Th>
                <Th
                  border="none"
                  color="var(--Text-Primary-description, #909090)"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="22px"
                  padding={'12px 12px 12px 0px'}
                  textAlign={'right'}
                >
                  Share
                </Th>
              </Tr>
            </Thead>

            <Tbody
              background={'var(--Cascader-colorWhite, #FFF)'}
              display={'flex'}
              flexDirection={'column'}
              gap={'12px'}
            >
              <Box
                width="100%"
                height="400px"
                overflowY="auto"
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                    background: '#F2F2F2',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#CCCCCC',
                    borderRadius: '24px',
                  },
                }}
              >
                {holdersData.map((holder, index) => (
                  <Tr
                    key={holder.id}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    borderBottom={'1px solid var(--Neutrals-4, #E9E9E9)'}
                  >
                    <Td border="none" padding={'12px 14px'}>
                      <Text
                        fontSize={'16px'}
                        fontWeight={'600'}
                        lineHeight={'normal'}
                        fontFamily="Poppins"
                        color={'#1A1A1A'}
                      >
                        {index + 1}.
                      </Text>
                    </Td>
                    <Td border="none" padding={'12px 14px'} flex={'0.5'} display={'flex'} alignItems={'flex-start'}>
                      <Text
                        fontSize={'14px'}
                        fontWeight={'500'}
                        lineHeight={'22px'}
                        fontFamily="Poppins"
                        color={'#1A1A1A'}
                      >
                        {holder.address_book_entry?.name || holder.holder_name || 'Unknown'}
                      </Text>
                    </Td>
                    <Td
                      border="none"
                      padding={'12px 14px'}
                      textAlign={'center'}
                      flex={'1'}
                      display={'flex'}
                      alignItems={'flex-start'}
                      gap={'8px'}
                    >
                      <BalanceIcon fill={'#39CA7F'} />
                      <Text
                        fontSize={'14px'}
                        fontWeight={'500'}
                        lineHeight={'22px'}
                        fontFamily="Poppins"
                        color={'var(--Text-Primary-description, #909090)'}
                      >
                        {formatBalance(holder.token_balance)}
                      </Text>
                    </Td>
                    <Td border="none" flex="1" padding={'12px 14px'}>
                      <Text
                        fontSize={'14px'}
                        fontWeight={'500'}
                        lineHeight={'22px'}
                        fontFamily="Poppins"
                        color={'var(--Text-Primary-description, #909090)'}
                      >
                        {formatWalletAddress(holder.wallet_address)}
                      </Text>
                    </Td>
                    <Td border="none" padding={'12px 12px 12px 0px'}>
                      <CircularProgress percentage={parseInt(holder.share_percentage)} />
                    </Td>
                  </Tr>
                ))}
              </Box>

              {/* Total row outside scroll area */}
              {/*
              <Tr
                borderRadius="10px"
                background="#F6F6F6"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={'12px'}
              >
                <Td border="none" flex="1" padding={'0px'}>
                  <Text
                    fontSize={'16px'}
                    fontWeight={'700'}
                    lineHeight={'normal'}
                    fontFamily="Poppins"
                    color={'#1A1A1A'}
                  >
                    Total Value Held:
                  </Text>
                </Td>
                <Td border="none" flex="1" padding={'0px'}>
                  <Box display={'flex'} justifyContent={'flex-end'}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'700'}
                      lineHeight={'normal'}
                      fontFamily="Poppins"
                      color={'#1A1A1A'}
                    >
                      {totalBalance
                        ? `${Number(totalBalance).toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                          })} ($DRINK)`
                        : tableData.totalValue}
                    </Text>
                  </Box>
                </Td>
              </Tr> */}
            </Tbody>
          </Table>
        </Box>
        <Box display="flex" height={'100%'} flexDirection="column" gap="32px">
          {biggestTransactions.map((item, index) => {
            const type = item.title.toLowerCase().includes('receive') ? 'receive' : 'send';
            return (
              <BiggestBS
                key={index}
                Name={item.Name}
                WalletImage={item.WalletImage}
                Date={item.Date}
                TotalAmount={item.TotalAmount}
                title={item.title}
                color={item.color}
                TxHash={item.TxHash}
                onTimeRangeChange={(range: string) => {
                  setTimeRanges((prev) => ({
                    ...prev,
                    [type]: range,
                  }));
                }}
                defaultTimeRange={timeRanges[type]}
                type={type}
              />
            );
          })}
        </Box>
      </Box>
      <Box
        display="flex"
        padding="24px"
        flexDirection="column"
        alignItems="center"
        gap="24px"
        alignSelf="stretch"
        borderRadius="36px"
        border="1px solid rgba(255, 255, 255, 0.10)"
        background="var(--Neutrals-1, #FFF)"
        boxShadow="0px 1px 7px 0px rgba(0, 0, 0, 0.08)"
      >
        <Box display={'flex'} justifyContent="space-between" width="100%">
          <Box
            display="flex"
            alignItems="center"
            gap="6px"
            alignSelf="stretch"
            color="var(--Text-Primary-default, #000)"
          >
            <InfoCircle boxSize="24px" />
            <div style={{ fontFamily: 'Poppins', fontSize: '20px', fontWeight: '700', lineHeight: '28px' }}>
              Latest Transactions
            </div>
          </Box>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'16px'}>
            <Text c="var(--Text-Primary-default, #000)" fs="14px" fw={500} lineHeight={'22px'}>
              Filters:
            </Text>
            <TransferTypeFilter
              activeFilter={transferTypeFilter}
              onFilterChange={(type: string) => {
                setTransferTypeFilter(type);
                setCurrentPage(1);
              }}
            />
            <DaysFilter
              onTimeRangeChange={(range) => {
                setLatestTxTimeRange(range);
                setCurrentPage(1);
              }}
              activeTimeRange={latestTxTimeRange}
            />
          </Box>
        </Box>
        <Table variant="unstyled" display={['none', 'none', 'none', 'table']}>
          <Thead display={'block'}>
            <Tr borderRadius={'10px'} background={'#F6F6F6'} display="flex" justifyContent="space-between">
              <Th
                border="none"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="22px"
                padding={'12px 14px'}
                flex="1"
              >
                Holder
              </Th>
              <Th
                border="none"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="22px"
                padding={'12px 14px'}
                flex="1"
                display="flex"
                alignItems="center"
                gap="4px"
              >
                Amount
                <BalanceFilter sort={amountSort} onSort={handleAmountSort} />
              </Th>

              <Th
                border="none"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="22px"
                padding={'12px 14px'}
                flex="1"
                display="flex"
                alignItems="center"
                gap="4px"
              >
                Date
                <BalanceFilter sort={dateSort} onSort={handleDateSort} />
              </Th>
              <Th
                border="none"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="22px"
                padding={'12px 14px'}
                flex="1"
              >
                Address
              </Th>
              <Th
                border="none"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="22px"
                padding={'12px 14px'}
                flex="1"
              >
                Type
              </Th>
              <Th
                display={'flex'}
                alignItems={'center'}
                border="none"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="22px"
                padding={'12px 14px'}
                flex="1"
                textAlign={'left'}
                justifyContent={'flex-end'}
              >
                TX Hash
              </Th>
            </Tr>
          </Thead>
          <Tbody background={'var(--Cascader-colorWhite, #FFF)'} display={'flex'} flexDirection={'column'} gap={'12px'}>
            {latestTransactions.map((item, index) => (
              <Tr
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottom={'1px solid var(--Neutrals-4, #E9E9E9)'}
              >
                <Td border="none" flex="1" padding={'12px 14px'}>
                  <Text c="var(--Text-Primary-default, #000)" fs="14px" fw={500} lineHeight={'22px'} textAlign={'left'}>
                    {item.address_book_name}
                  </Text>
                </Td>
                <Td border="none" flex="1" padding={'12px 14px'}>
                  <Text c="var(--Text-Primary-default, #000)" fs="14px" fw={500} lineHeight={'22px'} textAlign={'left'}>
                    <BalanceIcon fill={'#39CA7F'} />
                    {`${Number(item.amount).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })} ($DRINK)`}
                  </Text>
                </Td>
                <Td border="none" flex="1" padding={'12px 14px'}>
                  <Text c="var(--Text-Primary-default, #000)" fs="14px" fw={500} lineHeight={'22px'} textAlign={'left'}>
                    {formatDate(item.block_time)}
                  </Text>
                </Td>
                <Td border="none" flex="1" padding={'12px 14px'}>
                  <Text c="var(--Text-Primary-default, #000)" fs="14px" fw={500} lineHeight={'22px'} textAlign={'left'}>
                    {formatWalletAddress(item.address)}
                  </Text>
                </Td>
                <Td border="none" flex="1" padding={'12px 14px'}>
                  <Box borderRadius={'4px'} padding={'1px 8px'} width={'fit-content'}>
                    <SendReceiveBlock type={item.transfer_type === 'Receive' ? 'receive' : 'send'} />
                  </Box>
                </Td>
                <Td
                  onClick={() => openInNewTab(getExplorerUrl(Number(chainId), item.transaction_hash))}
                  cursor={'pointer'}
                  border="none"
                  flex="1"
                  gap={'4px'}
                  padding={'12px 14px'}
                  display={'flex'}
                  justifyContent={'flex-end'}
                >
                  <Text c="var(--Text-action, #4096FF)" fs="14px" fw="500" lineHeight="22px">
                    {formatWalletAddress(item.transaction_hash)}
                  </Text>
                  {/* <Image src={arrowOutward} alt="Details Icon" boxSize="20px" /> */}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <TablePagination
          currentPage={currentPage}
          totalCount={totalCount}
          onPageChange={handlePageChange}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      </Box>
    </div>
  );
};

export default AddressBooks;
