import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import CrossIcon from '../../assets/images/svgs/CrossIcon.svg';
import SearchIcon from '../../assets/images/svgs/SearchIcon.svg';
import { CalendarIcon } from '../../Icons/CalendarIcon';
import { TagIcon } from '../../Icons/TagIcon';
import TablePagination from '../../pages/TablePagination';
import { useLoginStore } from '../../store';
import SendReceiveBlock from '../common/SendReceiveBlock';
import AppModal from './AppModal';

interface SectionProps {
  showAll?: boolean;
  onShowAll?: () => void;
  searchQuery: string;
}

interface OverallSearchModalProps {
  onClose: () => void;
}

interface TokenHolder {
  id: number;
  address: string;
  balance: string;
  contract_decimals: string;
  labels: string[];
}

interface AddressBookEntry {
  id: number;
  wallet_address: string;
  name: string;
  created_at: string;
  updated_at: string;
}

interface Transaction {
  id: number;
  inflow_outflow: string | null;
  amount: string;
  receiver: string;
  sender: string;
  transfer_type: string;
  currency_symbol: string;
  currency_smart_contract: string;
  transaction_hash: string;
  transaction_to: string;
  transaction_value: string;
  transaction_type: string;
  transaction_value_usd: string;
  transaction_cost: string;
  transaction_from: string;
  block_number: number;
  block_time: string;
  user: number;
}

interface PaginationControlsProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number, category: string) => void;
  category: string;
}
interface SearchResponse {
  search_term: string;
  search_type: string;
  token_holders: {
    results: TokenHolder[];
    total_count: number;
    page_size: number;
    current_page: number;
    total_pages: number;
    is_paginated_category: boolean;
  };
  address_book: {
    results: AddressBookEntry[];
    total_count: number;
    page_size: number;
    current_page: number;
    total_pages: number;
    is_paginated_category: boolean;
  };
  transactions: {
    results: Transaction[];
    total_count: number;
    page_size: number;
    current_page: number;
    total_pages: number;
    is_paginated_category: boolean;
  };
}

const OverallSearchModal = ({ onClose }: OverallSearchModalProps) => {
  const navigate = useNavigate();
  const setSelectedWalletAddress = useLoginStore((state: any) => state.setSelectedWalletAddress);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const getCategoryFromTab = (tabIndex: number): string | null => {
    switch (tabIndex) {
      case 1:
        return 'token_holders';
      case 2:
        return 'address_book';
      case 3:
        return 'transactions';
      default:
        return null;
    }
  };

  const fetchSearchResults = async (query: string, page = 1, category: string | null = null) => {
    if (!query.trim()) {
      setSearchResults(null);
      return;
    }

    setIsLoading(true);
    try {
      const params: any = {
        q: query.trim(),
        page,
      };

      if (category) {
        params.category = category;
      }

      const response = await axios.get('api/user/search/', { params });
      setSearchResults(response.data);
    } catch (error) {
      console.error('Search error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      const category = getCategoryFromTab(activeTab);
      setCurrentPage(1); // Reset page when search query changes
      fetchSearchResults(searchQuery, 1, category);
    }
  }, [searchQuery, activeTab]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    const category = getCategoryFromTab(activeTab);
    if (category) {
      fetchSearchResults(searchQuery, newPage, category);
    }
  };
  const handleClear = () => {
    setSearchQuery('');
    setSearchResults(null);
  };

  const navigateToSinglePage = (address: string, labels: string[] = []) => {
    setSelectedWalletAddress(address);
    navigate(`/search/${address}`, {
      state: {
        address: address,
        labels: labels,
      },
    });
    onClose();
  };

  const TokenHoldersSection: React.FC<SectionProps> = ({ showAll = false, onShowAll }) => {
    const holders = searchResults?.token_holders?.results || [];
    const displayData = showAll ? holders : holders.slice(0, 3);
    const totalCount = searchResults?.token_holders?.total_count || 0;
    const totalPages = searchResults?.token_holders?.total_pages || 1;

    if (!searchQuery) {
      return <DefaultSearchState />;
    }

    if (holders.length === 0) {
      return <EmptySearchState searchQuery={searchQuery} />;
    }

    return (
      <Box display="flex" flexDirection="column" gap="8px" alignSelf="stretch" background="#FFF">
        <Text color="#909090" fontSize="12px" fontWeight="500">
          Token Holders
        </Text>
        <VStack gap={'9px'} maxH={showAll ? '650px' : '240px'} overflowY="auto" width={'100%'}>
          {displayData.map((holder) => (
            <Flex
              key={holder.id}
              width={'100%'}
              padding="10px 12px"
              alignItems="flex-start"
              gap="10px"
              justifyContent={'space-between'}
              borderRadius="14px"
              background="#FAFAFA"
              onClick={() => navigateToSinglePage(holder.address, holder.labels)}
              cursor="pointer"
              _hover={{ background: '#F0F0F0' }}
            >
              <Text color="#000" fontSize="14px" fontWeight="500">
                {`${holder.address.slice(0, 12)}...${holder.address.slice(-12)}`}
              </Text>
              <Flex alignItems="center" gap="10px">
                <Flex minWidth="140px" alignItems="center" gap="8px">
                  <TagIcon fill={holder.labels.length > 0 ? '#4096FF' : '#909090'} />
                  {holder.labels.length > 0 ? (
                    holder.labels.map((label, index) => (
                      <Text key={index} color="#909090" fontSize="14px" fontWeight="500">
                        {label}
                      </Text>
                    ))
                  ) : (
                    <Text color="#909090" fontSize="14px" fontWeight="500">
                      No Tag
                    </Text>
                  )}
                </Flex>
                {/* <Text color="#909090" fontSize="14px" fontWeight="500">
                  Balance: {parseFloat(holder.balance).toFixed(2)}
                </Text> */}
              </Flex>
            </Flex>
          ))}
        </VStack>
        {!showAll && holders.length > 0 ? (
          <Button
            variant="unstyled"
            color="#4096FF"
            fontSize="12px"
            textAlign={'left'}
            fontWeight="500"
            justifyContent={'flex-start'}
            onClick={() => {
              setActiveTab(1);
              onShowAll?.();
            }}
          >
            Show all results ({totalCount})
          </Button>
        ) : (
          showAll && (
            <TablePagination
              currentPage={currentPage}
              totalCount={totalCount}
              entriesPerPage={10}
              onPageChange={handlePageChange}
              hasNext={currentPage * 10 < totalCount}
              hasPrevious={currentPage > 1}
            />
          )
        )}
      </Box>
    );
  };

  const AddressBookSection: React.FC<SectionProps> = ({ showAll = false, onShowAll }) => {
    const entries = searchResults?.address_book?.results || [];
    const displayData = showAll ? entries : entries.slice(0, 3);
    const totalCount = searchResults?.address_book?.total_count || 0;
    const totalPages = searchResults?.address_book?.total_pages || 1;

    if (!searchQuery) {
      return <DefaultSearchState />;
    }

    if (entries.length === 0) {
      return <EmptySearchState searchQuery={searchQuery} />;
    }

    return (
      <Box display="flex" flexDirection="column" gap="8px" alignSelf="stretch" background="#FFF">
        <Text color="#909090" fontSize="12px" fontWeight="500">
          Address Book
        </Text>
        <VStack gap={'9px'} maxH={showAll ? '650px' : '240px'} overflowY="auto" width={'100%'}>
          {displayData.map((entry) => (
            <Flex
              key={entry.id}
              padding="10px 12px"
              width={'100%'}
              alignItems="center"
              gap="10px"
              justifyContent={'space-between'}
              borderRadius="14px"
              background="#FAFAFA"
              onClick={() => navigateToSinglePage(entry.wallet_address)}
              cursor="pointer"
              _hover={{ background: '#F0F0F0' }}
            >
              <Text color="#000" fontSize="14px" fontWeight="500">
                {entry.wallet_address && `${entry.wallet_address.slice(0, 12)}...${entry.wallet_address.slice(-12)}`}
              </Text>
              <Flex alignItems="center" gap="10px">
                <Flex minWidth="140px" alignItems="center" gap="8px">
                  <TagIcon fill={'#4096FF'} />
                  <Text color="#909090" fontSize="14px" fontWeight="500">
                    {entry.name}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          ))}
        </VStack>
        {!showAll && entries.length > 0 ? (
          <Button
            variant="unstyled"
            color="#4096FF"
            fontSize="12px"
            fontWeight="500"
            textAlign={'left'}
            justifyContent={'flex-start'}
            onClick={() => {
              setActiveTab(2);
              onShowAll?.();
            }}
          >
            Show all results ({totalCount})
          </Button>
        ) : (
          showAll && (
            <TablePagination
              currentPage={currentPage}
              totalCount={totalCount}
              entriesPerPage={10}
              onPageChange={handlePageChange}
              hasNext={currentPage * 10 < totalCount}
              hasPrevious={currentPage > 1}
            />
          )
        )}
      </Box>
    );
  };

  const TransactionsSection: React.FC<SectionProps> = ({ showAll = false, onShowAll }) => {
    const transactions = searchResults?.transactions?.results || [];
    const displayData = showAll ? transactions : transactions.slice(0, 2);
    const totalCount = searchResults?.transactions?.total_count || 0;
    const totalPages = searchResults?.transactions?.total_pages || 1;

    if (!searchQuery) {
      return <DefaultSearchState />;
    }

    if (transactions.length === 0) {
      return <EmptySearchState searchQuery={searchQuery} />;
    }

    const formatDate = (dateString: string) => {
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    };

    const formatAmount = (amount: string) => {
      return parseFloat(amount).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    };

    return (
      <Box display="flex" flexDirection="column" gap="8px" alignSelf="stretch" background="#FFF">
        <Text color="#909090" fontSize="12px" fontWeight="500">
          Transactions
        </Text>
        <VStack gap={'9px'} maxH={showAll ? '650px' : '240px'} overflowY="auto" width={'100%'}>
          {displayData.map((tx) => (
            <Flex
              key={tx.id}
              width={'100%'}
              padding="10px 12px"
              alignItems="center"
              gap="10px"
              borderRadius="14px"
              background="#FAFAFA"
              onClick={() => navigateToSinglePage(tx.transaction_from)}
              cursor="pointer"
              _hover={{ background: '#F0F0F0' }}
            >
              <Flex minWidth="150px" alignItems="center" gap="10px" justifyContent={'space-between'} flex="1 0 0">
                <Text color="#000" fontSize="14px" fontWeight="500">
                  {`${tx.transaction_from.slice(0, 12)}...${tx.transaction_from.slice(-12)}`}
                </Text>
                <Flex minWidth="110px" justifyContent="center" alignItems="center" gap="8px">
                  <CalendarIcon fill={'#909090'} />
                  <Text color="#909090" fontSize="14px" fontWeight="500">
                    {formatDate(tx.block_time)}
                  </Text>
                </Flex>
              </Flex>
              <Flex minWidth="150px" justifyContent="space-between" alignItems="center" flex="1 0 0">
                <SendReceiveBlock type={tx.transfer_type.toLowerCase() === 'send' ? 'send' : 'receive'} />
                <Flex minWidth="140px" alignItems="center" gap="8px">
                  <TagIcon fill={'#39CA7F'} />
                  <Text color="#909090" fontSize="14px" fontWeight="500">
                    {formatAmount(tx.amount)} {tx.currency_symbol}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          ))}
        </VStack>
        {!showAll && transactions.length > 0 ? (
          <Button
            variant="unstyled"
            color="#4096FF"
            fontSize="12px"
            textAlign={'left'}
            fontWeight="500"
            justifyContent={'flex-start'}
            onClick={() => {
              setActiveTab(3);
              onShowAll?.();
            }}
          >
            Show all results ({totalCount})
          </Button>
        ) : (
          showAll && (
            <TablePagination
              currentPage={currentPage}
              totalCount={totalCount}
              entriesPerPage={10}
              onPageChange={handlePageChange}
              hasNext={currentPage * 10 < totalCount}
              hasPrevious={currentPage > 1}
            />
          )
        )}
      </Box>
    );
  };

  const PaginationControls: React.FC<PaginationControlsProps> = ({
    currentPage,
    totalPages,
    onPageChange,
    category,
  }) => (
    <Flex justify="center" align="center" mt={4} gap={2}>
      <Button
        isDisabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1, category)}
        size="sm"
        colorScheme="blue"
        variant="outline"
      >
        Previous
      </Button>
      <Text color="#909090">
        Page {currentPage} of {totalPages}
      </Text>
      <Button
        isDisabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1, category)}
        size="sm"
        colorScheme="blue"
        variant="outline"
      >
        Next
      </Button>
    </Flex>
  );

  const tabLabels = ['All', 'Token Holders', 'Address Book', 'Transaction'];

  return (
    <AppModal
      open={true}
      centered={false}
      onClose={onClose}
      width={'fit-content'}
      style={{ top: 10 }}
      styles={{
        content: {
          width: '800px',
          // height: '90vh',
          // gap: '24px',
          // borderRadius: '36px',
          // border: '1px solid rgba(255, 255, 255, 0.10)',
          background: 'transparent',
          // boxShadow: '0px 1px 7px 0px rgba(0, 0, 0, 0.08)',
          // padding: '24px',
          flexDirection: 'column',
          padding: '0px',
        },
        body: { width: '100%', height: '100%' },
      }}
    >
      <Box
        gap={'24px'}
        display={'flex'}
        flexDirection={'column'}
        height={'fit-content'}
        bg={'#FFF'}
        borderRadius={'36px'}
        border={'1px solid rgba(255, 255, 255, 0.10)'}
        padding={'24px'}
        boxShadow={'0px 1px 7px 0px rgba(0, 0, 0, 0.08)'}
      >
        <Box display="flex" alignItems="center" gap="24px" alignSelf="stretch">
          <Flex width="fit-content" justify="space-between" align="center" flex={1}>
            <InputGroup
              flex={1}
              width="fit-content"
              padding="0px 20px 0px 12px"
              gap="10px"
              border="0.5px solid var(--Neutrals-4, #E9E9E9)"
              borderRadius="16px"
              background="var(--Neutrals-2, #F6F6F6)"
              alignItems="center"
            >
              <Image src={SearchIcon} alt="search" />
              <Input
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                border="none"
                color={'#000'}
                padding={0}
                placeholder="Search address, token holder, or transfer type "
                background="transparent"
                _placeholder={{
                  color: 'var(--Text-Primary-description, #909090)',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
                _focus={{
                  border: 'none',
                  boxShadow: 'none',
                }}
              />
            </InputGroup>
          </Flex>
          <Button
            onClick={handleClear}
            display="flex"
            padding="6px var(--Button-paddingContentHorizontal, 16px)"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="8px"
            borderRadius="16px"
            border="1px solid rgba(0, 0, 0, 0.15)"
            background="var(--Button-colorBgContainer, #FFF)"
            color="rgba(0, 0, 0, 0.88)"
            fontFamily="Poppins"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="22px"
            _hover={{ background: 'rgba(0, 0, 0, 0.05)' }}
          >
            Clear
          </Button>
          <Image onClick={onClose} cursor="pointer" src={CrossIcon} alt="Cross" />
        </Box>

        <Tabs index={activeTab} onChange={(index) => setActiveTab(index)}>
          <Flex gap={'24px'}>
            <TabList display="flex" alignItems="flex-start" gap="10px" alignSelf="stretch" border={'none'}>
              {tabLabels.map((label, index) => (
                <Tab
                  key={index}
                  display="flex"
                  padding="6px 16px"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gap="8px"
                  borderRadius="22px"
                  background="var(--Neutrals-2, #F6F6F6)"
                  border="none"
                  color="rgba(0, 0, 0, 0.88)"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="22px"
                  _selected={{
                    background: 'var(--blue-1, #E6F4FF)',
                    color: 'var(--Text-action, #4096FF)',
                  }}
                >
                  {label}
                </Tab>
              ))}
            </TabList>
          </Flex>

          <TabPanels>
            <TabPanel padding={0} paddingTop={'24px'}>
              {isLoading ? (
                <Flex justify="center" align="center" minHeight="200px">
                  <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#4096FF" size="xl" />
                </Flex>
              ) : !searchQuery ? (
                <DefaultSearchState />
              ) : searchResults &&
                searchResults.address_book.results.length === 0 &&
                searchResults.token_holders.results.length === 0 &&
                searchResults.transactions.results.length === 0 ? (
                <EmptySearchState searchQuery={searchQuery} />
              ) : (
                <VStack spacing="6" align="stretch">
                  <TokenHoldersSection searchQuery={searchQuery} onShowAll={() => setActiveTab(1)} />
                  <AddressBookSection searchQuery={searchQuery} onShowAll={() => setActiveTab(2)} />
                  <TransactionsSection searchQuery={searchQuery} onShowAll={() => setActiveTab(3)} />
                </VStack>
              )}
            </TabPanel>

            <TabPanel>
              {isLoading ? (
                <Flex justify="center" align="center" minHeight="200px">
                  <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#4096FF" size="xl" />
                </Flex>
              ) : (
                <TokenHoldersSection searchQuery={searchQuery} showAll={true} />
              )}
            </TabPanel>

            <TabPanel>
              {isLoading ? (
                <Flex justify="center" align="center" minHeight="200px">
                  <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#4096FF" size="xl" />
                </Flex>
              ) : (
                <AddressBookSection searchQuery={searchQuery} showAll={true} />
              )}
            </TabPanel>

            <TabPanel>
              {isLoading ? (
                <Flex justify="center" align="center" minHeight="200px">
                  <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#4096FF" size="xl" />
                </Flex>
              ) : (
                <TransactionsSection searchQuery={searchQuery} showAll={true} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </AppModal>
  );
};

export default OverallSearchModal;

const DefaultSearchState = () => (
  <Box display="flex" padding="64px 0px" flexDirection="column" alignItems="center" gap="14px">
    <Box
      display="flex"
      padding="24px"
      justifyContent="center"
      borderRadius="100px"
      background="var(--Neutrals-2, #F6F6F6)"
      alignItems="center"
      gap="10px"
    >
      <Image src={SearchIcon} alt="search" />
    </Box>
    <Text
      color="var(--Text-Primary-description, #909090)"
      textAlign="center"
      fontFamily="Poppins"
      fontSize="14px"
      fontWeight="500"
      lineHeight="22px"
    >
      Type to search and explore your data.
    </Text>
  </Box>
);

const EmptySearchState = ({ searchQuery }: { searchQuery: string }) => (
  <Box display="flex" padding="64px 0px" flexDirection="column" alignItems="center" gap="14px">
    <Box
      display="flex"
      padding="24px"
      justifyContent="center"
      borderRadius="100px"
      background="var(--Neutrals-2, #F6F6F6)"
      alignItems="center"
      gap="10px"
    >
      <Image src={SearchIcon} alt="search" />
    </Box>
    <Box display="flex" flexDirection="column" alignItems="center" gap="8px">
      <Text color="var(--Text-Primary-default, #000)" textAlign="center" fontFamily="Poppins" fontSize="16px">
        No results for {searchQuery}
      </Text>

      <Text
        color="var(--Text-Primary-description, #909090)"
        textAlign="center"
        fontFamily="Poppins"
        fontSize="14px"
        fontStyle="normal"
        fontWeight="500"
        lineHeight="22px"
      >
        No results found for your search. Please try using a different term.
      </Text>
    </Box>
  </Box>
);
