import { Box, Image, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { InfoCircle } from '../../Icons/InfoCircle';
import arrowOutward from '../../assets/images/svgs/arrowOutward.svg';
import { useLoginStore } from '../../store';
import { getExplorerUrl, openInNewTab } from '../../utils/walletUtils/walletutils';
import DaysFilter from './DaysFilter';
import SendReceiveBlock from './SendReceiveBlock';

interface BiggestBSProps {
  Name: string;
  WalletImage: string;
  Date: string;
  TotalAmount: string;
  title: string;
  color: string;
  TxHash: string;
  onTimeRangeChange: (range: string) => void;
  isLoading?: boolean;
  type: 'receive' | 'send';
  defaultTimeRange?: string;
}

const BiggestBS: React.FC<BiggestBSProps> = ({
  Name,
  TxHash,
  color,
  title,
  WalletImage,
  Date,
  TotalAmount,
  onTimeRangeChange,
  isLoading = false,
  type,
  defaultTimeRange = 'all',
}) => {
  const chainId = useLoginStore((state: any) => state.userData.blockchain_network.chain_id);
  const [localTimeRange, setLocalTimeRange] = useState(defaultTimeRange);

  const displayType = type === 'receive' ? 'receive' : 'send';

  const handleTimeRangeChange = (range: string) => {
    setLocalTimeRange(range);
    onTimeRangeChange(range);
  };

  return (
    <Box
      width={'534px'}
      display="flex"
      padding="24px"
      flexDirection="column"
      alignItems="flex-start"
      flex="1 0 0"
      alignSelf="stretch"
      borderRadius="36px"
      border="1px solid rgba(255, 255, 255, 0.10)"
      background="var(--Neutrals-1, #FFF)"
      boxShadow="0px 1px 7px 0px rgba(0, 0, 0, 0.08)"
    >
      <Box width={'100%'} gap="16px" height={'100%'} display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} justifyContent="space-between" width="100%" flexDirection={'row'} alignItems="center">
          <Box display="flex" alignItems="center" gap="6px" color="var(--Text-Primary-default, #000)">
            <InfoCircle boxSize="24px" />
            <Text fontFamily="Poppins" fontSize="20px" fontWeight="700" lineHeight="28px">
              {title}
            </Text>
          </Box>
          <DaysFilter onTimeRangeChange={handleTimeRangeChange} activeTimeRange={localTimeRange} />
        </Box>

        <Box display={'flex'} justifyContent="space-between" width="100%" flexDirection={'column'} gap={'12px'}>
          <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
            <SendReceiveBlock type={displayType} bgColor={color} />

            <Text
              color="var(--Dark-Tertiary-tr70, #878787)"
              textAlign="right"
              fontFamily="Poppins"
              fontSize="14px"
              fontWeight="600"
              lineHeight="22px"
            >
              {Date}
            </Text>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="flex-start" gap="8px" alignSelf="stretch">
            <Text
              color="var(--Text-Primary-default, #000)"
              fontFamily="Poppins"
              fontSize="56px"
              fontWeight="700"
              lineHeight="46px"
            >
              {TotalAmount}
            </Text>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" alignSelf="stretch" width="100%">
          <Box
            display="flex"
            padding="6px 12px 6px 8px"
            alignItems="center"
            gap="4px"
            borderRadius="6px"
            background="var(--Neutrals-2, #F6F6F6)"
          >
            <Image src={WalletImage} alt="Wallet Icon" boxSize="24px" />
            <Text
              color="var(--Text-Primary-description, #909090)"
              fontFamily="Poppins"
              fontSize="14px"
              fontWeight="500"
              lineHeight="22px"
            >
              {Name}
            </Text>
          </Box>

          {TxHash && (
            <Box
              onClick={() => openInNewTab(getExplorerUrl(Number(chainId), TxHash))}
              display="flex"
              cursor="pointer"
              padding="4px 0px"
              alignItems="center"
              gap="4px"
              borderRadius="6px"
              _hover={{ opacity: 0.8 }}
              transition="opacity 0.2s"
            >
              <Text
                color="var(--Text-action, #4096FF)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="600"
                lineHeight="22px"
              >
                View transaction details
              </Text>
              <Image src={arrowOutward} alt="Details Icon" boxSize="20px" />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BiggestBS;
