import { Box, Image, Text } from '@chakra-ui/react';
import React from 'react';
import ReceiveIcon from '../../assets/images/svgs/ReceiveIcon.svg';
import SendIcon from '../../assets/images/svgs/SendIcon.svg';

interface SendReceiveBlockProps {
  type: 'send' | 'receive';
  bgColor?: string;
}

const SendReceiveBlock: React.FC<SendReceiveBlockProps> = ({ type, bgColor }) => {
  const config = {
    send: {
      text: 'Send',
      icon: SendIcon,
      color: '#FF4D4F',
    },
    receive: {
      text: 'Receive',
      icon: ReceiveIcon,
      color: '#2FB16E',
    },
  };

  const currentConfig = config[type];

  return (
    <Box
      display="flex"
      padding="4px 12px 4px 4px"
      alignItems="center"
      gap="6px"
      borderRadius="16px"
      border="1px solid var(--Neutrals-4, #E9E9E9)"
      background={bgColor || '#FFF'}
    >
      <Box>
        <Image src={currentConfig.icon} alt={`${type} icon`} />
      </Box>
      <Text
        color={currentConfig.color}
        fontFamily="Poppins"
        fontSize="14px"
        fontStyle="normal"
        fontWeight="500"
        lineHeight="22px"
      >
        {currentConfig.text}
      </Text>
    </Box>
  );
};

export default SendReceiveBlock;
