// import { Button, Row } from 'antd';
// import React, { useEffect, useState } from 'react';
// import Cross from '../../assets/images/svgs/cross.svg';
// import { DataType } from '../../pages/AddressBook';
// import AppModal from './AppModal';
// import InputField from './InputField';

// interface IProps {
//   isShowModal: boolean;
//   setIsShowModal: (e: boolean) => void;
//   editingRecord: DataType | null;
//   onSaveChanges: (updatedRecord: DataType) => void;
//   isAddingNew: boolean;
// }

// const AddressBookEditModal: React.FC<IProps> = ({
//   isShowModal,
//   setIsShowModal,
//   editingRecord,
//   onSaveChanges,
//   isAddingNew,
// }) => {
//   const [formData, setFormData] = useState<Partial<DataType>>({
//     wallet_address: '',
//     name: '',
//   });

//   useEffect(() => {
//     if (editingRecord) {
//       setFormData({
//         id: editingRecord.id,
//         wallet_address: editingRecord.wallet_address,
//         name: editingRecord.name,
//       });
//     } else {
//       setFormData({
//         wallet_address: '',
//         name: '',
//       });
//     }
//   }, [editingRecord]);

//   const inputFields = [
//     { label: 'Name', key: 'name' as keyof DataType, placeholder: 'Enter name' },
//     { label: 'Wallet Address', key: 'wallet_address' as keyof DataType, placeholder: '0x545445455445' },
//   ];

//   const handleInputChange = (key: keyof DataType, value: string) => {
//     setFormData((prev) => ({ ...prev, [key]: value }));
//   };

//   const handleSaveChanges = () => {
//     onSaveChanges(formData as DataType);
//   };

//   return (
//     <AppModal open={isShowModal} onCancel={() => setIsShowModal(false)} onClose={() => setIsShowModal(false)}>
//       <Row
//         style={{
//           width: '401px',
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//           alignItems: 'center',
//           gap: '24px',
//         }}
//       >
//         <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch' }}>
//           <span
//             style={{
//               color: 'rgba(0, 0, 0, 0.88)',
//               textAlign: 'center',
//               fontFamily: 'Poppins',
//               fontSize: '24px',
//               fontStyle: 'normal',
//               fontWeight: 500,
//               lineHeight: '24px',
//             }}
//           >
//             {isAddingNew ? 'Add new address' : 'Edit your entry'}
//           </span>
//           <div style={{ cursor: 'pointer' }} onClick={() => setIsShowModal(false)}>
//             <img src={Cross} alt="Close" />
//           </div>
//         </Row>
//         <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
//           {inputFields.map((field) => (
//             <InputField
//               key={field.key}
//               label={field.label}
//               placeholder={field.placeholder}
//               value={formData[field.key] || ''}
//               onChange={(value) => handleInputChange(field.key, value)}
//             />
//           ))}
//         </div>
//         <Button
//           onClick={handleSaveChanges}
//           style={{
//             display: 'flex',
//             padding: '0px 16px',
//             flexDirection: 'column',
//             justifyContent: 'center',
//             alignItems: 'center',
//             gap: '8px',
//             alignSelf: 'stretch',
//             borderRadius: '6px',
//             border: '1px solid #2D8DFB',
//             background: '#2D8DFB',
//             color: '#FFF',
//             fontFamily: 'Poppins',
//             fontSize: '14px',
//             fontStyle: 'normal',
//             fontWeight: 400,
//             lineHeight: '22px',
//           }}
//         >
//           Save Changes
//         </Button>
//       </Row>
//     </AppModal>
//   );
// };

// export default AddressBookEditModal;

import { Button, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import Cross from '../../assets/images/svgs/CrossIcon.svg';
import AppModal from './AppModal';
import InputField from './InputField';
// In AddressBookEditModal.tsx
import { TokenHolder } from '../../types/index'; // Import the shared interface

interface IProps {
  isShowModal: boolean;
  setIsShowModal: (e: boolean) => void;
  editingRecord: TokenHolder | null;
  onSaveChanges: (updatedRecord: TokenHolder) => void;
  isAddingNew: boolean;
}

const AddressBookEditModal: React.FC<IProps> = ({
  isShowModal,
  setIsShowModal,
  editingRecord,
  onSaveChanges,
  isAddingNew,
}) => {
  const [formData, setFormData] = useState<Partial<TokenHolder>>({
    wallet_address: '',
    name: '',
  });

  useEffect(() => {
    if (editingRecord) {
      setFormData({
        id: editingRecord.id,
        wallet_address: editingRecord.wallet_address,
        name: editingRecord.name,
        address: editingRecord.address,
        balance: editingRecord.balance,
        contract_decimals: editingRecord.contract_decimals,
        labels: editingRecord.labels,
        created_at: editingRecord.created_at,
        updated_at: editingRecord.updated_at,
      });
    } else {
      setFormData({
        wallet_address: '',
        name: '',
      });
    }
  }, [editingRecord]);

  const inputFields = [
    {
      label: 'Name',
      key: 'name',
      placeholder: 'Enter name',
    },
    {
      label: 'Wallet Address',
      key: 'wallet_address',
      placeholder: '0x545445455445',
    },
  ] as const;

  const handleInputChange = (key: 'name' | 'wallet_address', value: string) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSaveChanges = () => {
    if (formData.wallet_address && formData.name) {
      const updatedRecord = {
        ...formData,
        id: editingRecord?.id || 0,
        address: formData.wallet_address,
        balance: editingRecord?.balance || '0',
        contract_decimals: editingRecord?.contract_decimals || '18',
        labels: editingRecord?.labels || [],
        created_at: editingRecord?.created_at || new Date().toISOString(),
        updated_at: new Date().toISOString(),
      } as TokenHolder;

      onSaveChanges(updatedRecord);
    }
  };

  return (
    <AppModal open={isShowModal} onCancel={() => setIsShowModal(false)} onClose={() => setIsShowModal(false)}>
      <Row
        style={{
          display: 'flex',
          width: '390px',

          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'stretch',
          }}
        >
          <span
            style={{
              color: 'var(--Text-Black, #000)',
              textAlign: 'center',
              fontFamily: 'Poppins',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '28px',
            }}
          >
            {isAddingNew ? 'Add new address' : 'Edit your entry'}
          </span>
          <div style={{ cursor: 'pointer' }} onClick={() => setIsShowModal(false)}>
            <img src={Cross} alt="Close" style={{ position: 'absolute', right: '0px', top: '0px' }} />
          </div>
        </Row>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '12px',
            alignSelf: 'stretch',
          }}
        >
          {inputFields.map((field) => (
            <InputField
              key={field.key}
              label={field.label}
              placeholder={field.placeholder}
              value={formData[field.key] || ''}
              onChange={(value) => handleInputChange(field.key, value)}
            />
          ))}

          <Button
            onClick={handleSaveChanges}
            style={{
              height: 'var(--Button-controlHeight, 32px)',
              display: 'flex',
              padding: '6px var(--Button-paddingContentHorizontal, 16px)',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              alignSelf: 'stretch',
              borderRadius: '16px',
              border: '1px solid #2D8DFB',
              background: '#2D8DFB',
              color: 'var(--Button-colorTextLightSolid, #FFF)',
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '22px',
            }}
          >
            Save Changes
          </Button>
        </div>
      </Row>
    </AppModal>
  );
};

export default AddressBookEditModal;
