import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const InfoCircle = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <mask id="mask0_3506_21781" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
      <rect width="22" height="22" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_3506_21781)">
      <path
        d="M10.9987 15.5833C11.2584 15.5833 11.4761 15.4955 11.6518 15.3198C11.8275 15.1441 11.9154 14.9264 11.9154 14.6666V11C11.9154 10.7403 11.8275 10.5225 11.6518 10.3469C11.4761 10.1712 11.2584 10.0833 10.9987 10.0833C10.739 10.0833 10.5213 10.1712 10.3456 10.3469C10.1699 10.5225 10.082 10.7403 10.082 11V14.6666C10.082 14.9264 10.1699 15.1441 10.3456 15.3198C10.5213 15.4955 10.739 15.5833 10.9987 15.5833ZM10.9987 8.24998C11.2584 8.24998 11.4761 8.16213 11.6518 7.98644C11.8275 7.81074 11.9154 7.59303 11.9154 7.33331C11.9154 7.07359 11.8275 6.85588 11.6518 6.68019C11.4761 6.50449 11.2584 6.41665 10.9987 6.41665C10.739 6.41665 10.5213 6.50449 10.3456 6.68019C10.1699 6.85588 10.082 7.07359 10.082 7.33331C10.082 7.59303 10.1699 7.81074 10.3456 7.98644C10.5213 8.16213 10.739 8.24998 10.9987 8.24998ZM10.9987 20.1666C9.73064 20.1666 8.53898 19.926 7.4237 19.4448C6.30842 18.9635 5.33828 18.3104 4.51328 17.4854C3.68828 16.6604 3.03516 15.6903 2.55391 14.575C2.07266 13.4597 1.83203 12.268 1.83203 11C1.83203 9.73192 2.07266 8.54026 2.55391 7.42498C3.03516 6.3097 3.68828 5.33956 4.51328 4.51456C5.33828 3.68956 6.30842 3.03644 7.4237 2.55519C8.53898 2.07394 9.73064 1.83331 10.9987 1.83331C12.2668 1.83331 13.4584 2.07394 14.5737 2.55519C15.689 3.03644 16.6591 3.68956 17.4841 4.51456C18.3091 5.33956 18.9622 6.3097 19.4435 7.42498C19.9247 8.54026 20.1654 9.73192 20.1654 11C20.1654 12.268 19.9247 13.4597 19.4435 14.575C18.9622 15.6903 18.3091 16.6604 17.4841 17.4854C16.6591 18.3104 15.689 18.9635 14.5737 19.4448C13.4584 19.926 12.2668 20.1666 10.9987 20.1666Z"
        fill="#C2C2C2"
      />
    </g>
  </Icon>
);
