import { Button, Row } from 'antd';
import React from 'react';
import Cross from '../../assets/images/svgs/CrossIcon.svg';
import AppModal from './AppModal';

interface IProps {
  showModal: boolean;
  isShowModal: (e: boolean) => void;
  onConfirmDelete: () => void;
}

const DeleteModal: React.FC<IProps> = ({ showModal, isShowModal, onConfirmDelete }) => {
  return (
    <AppModal
      open={showModal}
      onCancel={() => isShowModal(false)}
      onClose={() => isShowModal(false)}
      styles={{
        content: {
          display: 'flex',
          width: '390px',
          padding: '24px',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '24px',
          borderRadius: '36px',
          border: '1px solid rgba(255, 255, 255, 0.10)',
          background: 'var(--Neutrals-1, #FFF)',
          boxShadow: '0px 1px 7px 0px rgba(0, 0, 0, 0.08)',
        },
        body: { width: '100%', height: '100%' },
      }}
    >
      <Row style={{ position: 'relative', gap: '12px', justifyContent: 'center' }}>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '12px',
            alignSelf: 'stretch',
            justifyContent: 'center',
          }}
        >
          <span
            style={{
              color: 'rgba(0, 0, 0, 0.88)',
              textAlign: 'center',
              fontFamily: 'Poppins',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
            }}
          >
            Delete your entry
          </span>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              isShowModal(false);
            }}
          >
            <img src={Cross} alt="Close" style={{ position: 'absolute', right: '0px', top: '0px' }} />
          </div>
          <span
            style={{
              maxWidth: '326px',
              width: '100%',
              color: 'var(--Text-Primary-description, #909090)',
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              textAlign: 'center',
            }}
          >
            Are you sure you want to delete this entry? This action cannot be undone.
          </span>
        </Row>

        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '10px',
            width: '100%',
            alignSelf: 'stretch',
          }}
        >
          <Button
            onClick={() => isShowModal(false)}
            style={{
              width: '100%',
              display: 'flex',
              padding: '6px var(--Button-paddingContentHorizontal, 16px)',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              flex: '1 0 0',
              borderRadius: '16px',
              border: '1px solid rgba(0, 0, 0, 0.15)',
              color: 'rgba(0, 0, 0, 0.88)',
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '22px',
              background: 'var(--Button-colorBgContainer, #FFF)',
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={() => {
              onConfirmDelete();
              isShowModal(false);
            }}
            style={{
              width: '100%',
              display: 'flex',
              padding: '6px var(--Button-paddingContentHorizontal, 16px)',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              flex: '1 0 0',
              borderRadius: '16px',
              border: '1px solid rgba(0, 0, 0, 0.15)',
              color: ' var(--Button-colorTextLightSolid, #FFF)',
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '22px',
              background: 'var(--Button-colorError, #FF4D4F)',
            }}
          >
            Delete
          </Button>
        </div>
      </Row>
    </AppModal>
  );
};

export default DeleteModal;
