import { Box } from '@chakra-ui/react';
import React from 'react';
import Text from './Text';

interface TransferTypeFilterProps {
  activeFilter: string;
  onFilterChange: (type: string) => void;
}

const TransferTypeFilter: React.FC<TransferTypeFilterProps> = ({ activeFilter, onFilterChange }) => {
  const filterTypes = [
    { label: 'All', value: 'all' },
    { label: 'Receive', value: 'Receive' },
    { label: 'Send', value: 'Send' },
  ];

  return (
    <Box
      display="flex"
      padding="5px"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="14px"
      background="var(--Neutrals-2, #F6F6F6)"
      marginRight="16px"
    >
      {filterTypes.map(({ label, value }) => (
        <Box
          key={value}
          display="flex"
          padding="6px 12px"
          justifyContent="center"
          alignItems="center"
          gap="10px"
          flex="1 0 0"
          alignSelf="stretch"
          borderRadius="10px"
          boxShadow={activeFilter === value ? '0px 1px 3px 0px rgba(0, 0, 0, 0.19)' : 'none'}
          background={activeFilter === value ? '#FFF' : 'transparent'}
          cursor="pointer"
          onClick={() => onFilterChange(value)}
          transition="background 0.2s"
          _hover={{
            background: activeFilter === value ? '#FFF' : 'rgba(255, 255, 255, 0.1)',
          }}
        >
          <Text
            fs="14px"
            fw={500}
            lineHeight={'22px'}
            c={
              activeFilter === value ? 'var(--Text-Primary-default, #000)' : 'var(--Text-Primary-description, #909090)'
            }
          >
            {label}
          </Text>
        </Box>
      ))}
    </Box>
  );
};

export default TransferTypeFilter;
