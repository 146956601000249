import { Text as ChakraText, TextProps as ChakraTextProps } from '@chakra-ui/react';
import React from 'react';

interface TextProps extends ChakraTextProps {
  c?: string; // color shortcut
  ff?: string; // font family shortcut
  fs?: string; // font size shortcut
  fw?: string | number; // font weight shortcut
  ta?: 'left' | 'right' | 'center' | 'justify'; // text align shortcut
  children?: React.ReactNode;
}

const Text = ({ c, ff, fs, fw, ta, children, ...props }: TextProps): JSX.Element => {
  const defaultStyles = {
    color: 'var(--Text-Black, #000)',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
  } as const;

  // Combine shortcuts with props
  const combinedStyles = {
    ...defaultStyles,
    ...(c && { color: c }),
    ...(ff && { fontFamily: ff }),
    ...(fs && { fontSize: fs }),
    ...(fw && { fontWeight: fw }),
    ...(ta && { textAlign: ta }),
    ...props,
  };

  return <ChakraText {...combinedStyles}>{children}</ChakraText>;
};

export default Text;
