import { Button, Flex, Image } from '@chakra-ui/react';
import React from 'react';
import arrowRight from '../../assets/images/svgs/arrowRight.svg';
import CalendarOutlined from '../../assets/images/svgs/CalendarOutlined.svg';
import Text from './Text';

interface DateRangeProps {
  onFetch?: () => void;
}

const FetchTx: React.FC<DateRangeProps> = ({ onFetch }) => {
  return (
    <Flex display="flex" width="306px" justifyContent="space-between" alignItems="center">
      <Flex
        display="flex"
        padding="10px 10px 10px var(--DatePicker-controlPaddingHorizontal, 12px)"
        alignItems="center"
        gap="10px"
        borderRadius="16px"
        background="var(--Neutrals-2, #F6F6F6)"
      >
        <Text c="var(--Neutrals-6, #909090)" fs="14px" fw={400} lineHeight={'22px'}>
          Start Date
        </Text>
        <Image src={arrowRight} />

        <Text c="var(--Neutrals-6, #909090)" fs="14px" fw={400} lineHeight={'22px'}>
          End Date
        </Text>
        <Image src={CalendarOutlined} />
      </Flex>
      <Button
        display="flex"
        padding="6px var(--Button-paddingContentHorizontal, 16px)"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="8px"
        borderRadius="16px"
        border="1px solid rgba(0, 0, 0, 0.15)"
        background="var(--Button-colorBgContainer, #FFF)"
        color="rgba(0, 0, 0, 0.88)"
        fontFamily="Poppins"
        fontSize="14px"
        fontStyle="normal"
        fontWeight="500"
        lineHeight="22px"
      >
        Fetch
      </Button>
    </Flex>
  );
};

export default FetchTx;
