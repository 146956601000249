// import React, { useEffect, useState } from 'react';
// import { Box, Text, Image, HStack, Tag, TagLabel, Divider, useToast, Spinner } from '@chakra-ui/react';
// import { Table } from 'antd';
// import { useLocation } from 'react-router-dom';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import { CopyIcon } from '@chakra-ui/icons';
// import MetaMaskImage from '../../../assets/images/pngs/metamask-seeklogo.com 1.png';
// import { TagType, WalletInformation } from '../../../interfaces/tokenHolderInterface';
// import { TextHeading } from './TextInfoHeading';
// import configureAxios from '../../../AxiosClient';
// import { loadingStore, useLoginStore } from '../../../store';
// import { TokenHolderMain } from './TokenInfoMain';
// import { getTagsColor } from '../../../utils/helper';
// import { shortenWalletAddress, openInNewTab, getExplorerUrl } from '../../../utils/walletUtils/walletutils';
// import axios from '../../../api/axios';

// export const WalletInfo = () => {
//   const { state } = useLocation();
//   const toast = useToast();
//   const axiosInstance = configureAxios();
//   const setLoading = loadingStore((state: any) => state.setLoading);
//   const loading = loadingStore((state: any) => state.loading);
//   const [singleTokenHolder, setSingleTokenHolder] = useState<WalletInformation[]>([]);
//   const [tags, setTags] = useState<TagType[]>([]);
//   const walletAddress = useLoginStore((state: any) => state.walletAddress);
//   const [transactions, setTransactions] = useState([]);
//   const chainId = useLoginStore((state: any) => state.userData.blockchain_network.chain_id);

//   useEffect(() => {
//     console.log('chainId updated:', chainId);
//   }, [chainId]);

//   const getTokenHoldersForWallet = () => {
//     axios
//       .get('/api/token-holders/wallet-tokens/', {
//         params: {
//           wallet_address: walletAddress,
//         },
//       })
//       .then((res) => {
//         if (res.status === 200) {
//           setLoading(false);
//           const adjustedTokens = res.data.map((token: WalletInformation) => ({
//             ...token,
//             token_balance: (Number(token.token_balance) / Math.pow(10, 18)).toFixed(3),
//           }));
//           setSingleTokenHolder(adjustedTokens);
//         } else {
//           setLoading(false);
//           // toast({
//           //   title: 'There was an error fetching data',
//           //   status: 'error',
//           //   isClosable: true,
//           // });
//         }
//       })
//       .catch((err: unknown) => {
//         setLoading(false);
//         console.log(err);
//         // toast({
//         //   title: 'Error fetching token holder data',
//         //   description: 'Please try again later',
//         //   status: 'error',
//         //   isClosable: true,
//         // });
//       });
//   };

//   const getTransactions = () => {
//     axios
//       .get(`/api/user/tokenholderTransactions/?address=${walletAddress}`)
//       .then((res) => {
//         if (res.status === 200 && res.data && res.data.results) {
//           setTransactions(res.data.results);
//         } else {
//           // toast({
//           //   title: 'There was an error fetching transactions',
//           //   status: 'error',
//           //   isClosable: true,
//           // });
//         }
//       })
//       .catch((err: unknown) => {
//         console.log(err);
//         // toast({
//         //   title: 'Error fetching transaction data',
//         //   description: 'Please try again later',
//         //   status: 'error',
//         //   isClosable: true,
//         // });
//       });
//   };

//   useEffect(() => {
//     setLoading(true);

//     getTokenHoldersForWallet();
//     getTransactions();
//   }, [walletAddress]);

//   useEffect(() => {
//     setTags(getTagsColor(state?.labels) || []);
//   }, [state?.labels]);

//   const onAddressCopy = (text: string) => {
//     if (text !== '') {
//       toast({
//         title: 'Address Copied to clipboard',
//         status: 'success',
//         isClosable: true,
//       });
//     }
//   };

//   const columns = [
//     {
//       title: 'From',
//       dataIndex: 'sender',
//       key: 'sender',
//       render: (text: string) => shortenWalletAddress(text),
//     },
//     {
//       title: 'To',
//       dataIndex: 'receiver',
//       key: 'receiver',
//       render: (text: string) => shortenWalletAddress(text),
//     },
//     {
//       title: 'Amount',
//       dataIndex: 'amount',
//       key: 'amount',
//       render: (text: string) => Math.round(parseFloat(text)).toString(),
//     },
//     {
//       title: 'Currency',
//       dataIndex: 'currency_symbol',
//       key: 'currency_symbol',
//     },
//     {
//       title: 'Tx Hash',
//       dataIndex: 'transaction_hash',
//       key: 'transaction_hash',
//       render: (text: string, record: any) => (
//         <Text
//           style={{ cursor: 'pointer', color: '#722ED1' }}
//           onClick={() => openInNewTab(getExplorerUrl(Number(chainId), text))}
//         >
//           {shortenWalletAddress(text)}
//         </Text>
//       ),
//     },
//     {
//       title: 'Block Time',
//       dataIndex: 'block_time',
//       key: 'block_time',
//     },
//   ];

//   return (
//     <Box>
//       <Box display="flex" flexDirection={['column', 'column', 'row']} gap={['40px', '40px', '10px', '40px']}>
//         <Box width={['auto', 'auto', '60%', '608px']}>
//           <TextHeading text="Wallet" />
//           <Box
//             width={['inherit', 'inherit', 'auto', 'inherit']}
//             borderRadius={'20px'}
//             backgroundColor="#FFF"
//             padding={'24px'}
//             mt="10px"
//             minHeight={'615px'}
//             height={'100%'}
//           >
//             <Box height={'65px'} display="flex" alignItems="center" justifyContent={'space-between'}>
//               <Box display={'flex'} gap="10px">
//                 <Image src={MetaMaskImage} />
//                 <Text fontSize={'18px'} fontWeight="600" color="#000">
//                   {shortenWalletAddress(state?.address || walletAddress)}
//                 </Text>
//                 <CopyToClipboard text={state?.address || walletAddress} onCopy={onAddressCopy}>
//                   <CopyIcon style={{ cursor: 'pointer' }} />
//                 </CopyToClipboard>
//               </Box>
//               <Box>
//                 <HStack spacing={4}>
//                   {tags.map((tag, index) => (
//                     <Tag
//                       key={tag.key || `tag-${index}`}
//                       borderRadius={'8px'}
//                       border={`1px solid ${tag?.borderColor}`}
//                       size={tag?.size}
//                       variant="solid"
//                       backgroundColor={tag?.bgColor}
//                     >
//                       <TagLabel color={tag?.textColor} fontSize={'14px'}>
//                         {tag?.text}
//                       </TagLabel>
//                     </Tag>
//                   ))}
//                 </HStack>
//               </Box>
//             </Box>
//             <Divider />

//             <Box marginTop={'16px'}>
//               <Text fontWeight={600} fontSize={'18px'} color=" #000">
//                 Token
//               </Text>
//             </Box>
//             {loading ? (
//               <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
//             ) : (
//               <TokenHolderMain singleTokenHolder={singleTokenHolder} />
//             )}
//           </Box>
//         </Box>
//         <Box width={['auto', 'auto', '40%', 'auto']}>
//           <TextHeading text="Transactions" />
//           <Box
//             width={['inherit', 'inherit', 'auto', 'inherit']}
//             borderRadius={'20px'}
//             backgroundColor="#FFF"
//             padding={'24px'}
//             mt="10px"
//             minHeight={'615px'}
//             height={'100%'}
//             overflowX="auto"
//           >
//             <Table
//               dataSource={transactions}
//               columns={columns}
//               rowKey={(record) => record.id || Math.random().toString()}
//               pagination={{ pageSize: 10 }}
//               scroll={{ x: true }}
//             />
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

import { Box, Flex, Image, Input, InputGroup, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../../api/axios';
import Arrow from '../../../assets/images/svgs/arrow-swap-horizontal.svg';
import BackIcon from '../../../assets/images/svgs/BackArrow.svg';
import Copy from '../../../assets/images/svgs/copy.svg';
import ExternalLink from '../../../assets/images/svgs/ExternalLink.svg';
import Info from '../../../assets/images/svgs/Info.svg';
import SearchIcon from '../../../assets/images/svgs/SearchIcon.svg';
import { BalanceIcon } from '../../../Icons/BalanceIcon';
import { FilterTag } from '../../../Icons/FilterTag';
import { TagIcon } from '../../../Icons/TagIcon';
import TablePagination from '../../../pages/TablePagination';
import { useLoginStore } from '../../../store';
import { getExplorerUrl, openInNewTab } from '../../../utils/walletUtils/walletutils';
import AssestComp from '../../common/AssestComp';
import DatePicker from '../../common/DatePicker';
import SendReceiveBlock from '../../common/SendReceiveBlock';
import Text from '../../common/Text';

interface InflowOutflow {
  id: number;
  title: string;
  address: string;
  is_receiver: boolean;
}

interface ContractDetails {
  name: string;
  symbol: string;
  decimals: string;
}

interface Stats {
  balance: string;
  label: string;
  address: string;
  contract_details: ContractDetails;
  latest_transaction: Transaction;
  last_updated: string;
}

interface Transaction {
  id: number;
  inflow_outflow: {
    id: number;
    title: string;
    address: string;
    is_receiver: boolean;
  } | null;
  amount: string;
  receiver: string;
  sender: string;
  transfer_type: 'Send' | 'Receive';
  currency_symbol: string;
  currency_smart_contract: string;
  transaction_hash: string;
  transaction_to: string;
  transaction_value: string;
  transaction_type: string;
  transaction_value_usd: string;
  transaction_cost: string;
  transaction_from: string;
  block_number: number;
  block_time: string;
  user: number;
}

interface TransactionResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Transaction[];
}

const WalletInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const walletAddress = location.pathname.split('/').pop() || '';

  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [transactionData, setTransactionData] = useState<TransactionResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [currencyFilter, setCurrencyFilter] = useState('all');
  const [firstTransaction, setFirstTransaction] = useState<Transaction | null>(null);
  const [stats, setStats] = useState<Stats | null>(null);
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  const ITEMS_PER_PAGE = 10;

  const getTransferType = (type: string): 'send' | 'receive' => {
    return type.toLowerCase() === 'send' ? 'send' : 'receive';
  };

  const chainId = useLoginStore((state: any) => state.userData.blockchain_network.chain_id);

  useEffect(() => {
    console.log('chainId updated:', chainId);
  }, [chainId]);

  const getTransactions = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get<TransactionResponse>(
        `/api/user/tokenholderTransactions/?address=${walletAddress}&page=${currentPage}&page_size=${ITEMS_PER_PAGE}`,
      );
      if (response.data && response.data.results) {
        setTransactions(response.data.results);
        setTransactionData(response.data);
        // Set the first transaction here
        if (response.data.results.length > 0) {
          setFirstTransaction(response.data.results[0]);
        }
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, walletAddress]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  const getStats = useCallback(async () => {
    try {
      const response = await axios.get<{ stats: Stats }>(`/api/user/tokenholderTransactions/?address=${walletAddress}`);
      if (response.data && response.data.stats) {
        setStats(response.data.stats);
      }
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  }, [walletAddress]);

  useEffect(() => {
    getTransactions();
    getStats();
  }, [getTransactions, getStats]);

  const filteredAndPaginatedData = useMemo(() => {
    let filtered = transactions;

    if (searchTerm) {
      filtered = filtered.filter(
        (tx) =>
          tx.sender.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tx.receiver.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tx.currency_symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tx.transaction_hash.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    if (currencyFilter !== 'all') {
      filtered = filtered.filter((tx) => tx.currency_symbol === currencyFilter);
    }

    return {
      data: filtered,
      totalCount: transactionData?.count || 0,
      hasNext: !!transactionData?.next,
      hasPrevious: !!transactionData?.previous,
    };
  }, [transactions, searchTerm, currencyFilter, transactionData]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const formatBlockTime = (dateString: string) => {
    try {
      const date = new Date(dateString);
      return date
        .toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
        .replace(',', '');
    } catch (error) {
      console.error('Error formatting date:', error);
      return dateString;
    }
  };
  const getTransactionAddresses = (transaction: Transaction, statsAddress: string) => {
    const isSend = transaction.transfer_type.toLowerCase() === 'send';

    if (isSend) {
      return {
        leftAddress: transaction.sender, // Left shows sender
        rightAddress: transaction.receiver, // Right shows receiver
      };
    } else {
      return {
        leftAddress: transaction.receiver, // Left shows receiver
        rightAddress: transaction.sender, // Right shows sender
      };
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleCurrencyFilter = () => {
    setCurrencyFilter(currencyFilter === 'all' ? 'Drink' : 'all');
    setCurrentPage(1);
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setShowCopyNotification(true);
      // Hide the notification after 2 seconds
      setTimeout(() => {
        setShowCopyNotification(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const getPreviousPath = () => {
    if (location.state?.from) {
      return location.state.from;
    }

    const pathMap = {
      '/addressBooks/': '/addressBook',
      '/holder/': '/tokenHolders',
      '/demo/': '/SankeyChart',
      '/transactions/': '/transactions',
      '/tokenHolders/': '/tokenHolders',
      '/search/': '/tokenHolders',
    };

    const currentPath = location.pathname;
    for (const [key, value] of Object.entries(pathMap)) {
      if (currentPath.includes(key)) {
        return value;
      }
    }

    return '/';
  };

  const formatWalletAddress = (address: string) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const handleBack = () => {
    const previousPath = getPreviousPath();
    if (previousPath === -1) {
      navigate(-1);
    } else {
      navigate(previousPath);
    }
  };

  if (loading) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box
      display="flex"
      padding="14px 24px 40px 24px"
      flexDirection="column"
      alignItems="flex-start"
      gap="36px"
      flex="1 0 0"
      alignSelf="stretch"
    >
      <Box
        display="flex"
        padding="6px var(--Button-paddingContentHorizontal, 16px)"
        justifyContent="center"
        alignItems="center"
        gap="8px"
        borderRadius="16px"
        border="1px solid rgba(0, 0, 0, 0.15)"
        background="var(--Button-colorBgContainer, #FFF)"
      >
        <Box
          display="flex"
          height="var(--Button-controlHeight, 32px)"
          justifyContent="center"
          alignItems="center"
          gap="var(--Button-marginXS, 8px)"
          cursor={'pointer'}
          onClick={handleBack}
        >
          <Image src={BackIcon} />
          <Text fs="14px" fw={500} lineHeight={'22px'}>
            Back to list
          </Text>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="flex-start"
        gap="12px"
        height="fit-content"
        alignSelf="stretch"
      >
        <Text fs="36px" lineHeight={'28px'} c="var(--Text-Primary-default, #000)">
          Wallet Overview
        </Text>
        <Text fs="14px" fw={400} lineHeight="22px" c="var(--Text-Primary-default, #000)">
          Explore wallet assets, transaction history, and more in one place.”
        </Text>
      </Box>
      <Box display="flex" alignItems="flex-start" gap="14px" alignSelf="stretch" height={'100%'}>
        <Box
          display="flex"
          padding="24px"
          flexDirection="column"
          alignItems="flex-start"
          gap="12px"
          flex="1 0 0"
          alignSelf="stretch"
          borderRadius="36px"
          border="1px solid rgba(255, 255, 255, 0.10)"
          background="var(--Neutrals-1, #FFF)"
          boxShadow="0px 1px 7px 0px rgba(0, 0, 0, 0.08)"
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" alignSelf="stretch">
            <Box display="flex" width="97px" justifyContent="space-between" alignItems="center">
              <Image src={Info} />
              <Text fs="16px" lineHeight="28px">
                Balance
              </Text>
            </Box>
            <Box
              display="flex"
              padding="6px 12px 6px 6px"
              justifyContent="center"
              alignItems="center"
              gap="6px"
              borderRadius="10px"
              border="1px solid rgba(0, 0, 0, 0.05)"
              background="#FFF"
            >
              <TagIcon fill={'#4096FF'} />
              <Text fs="16px" lineHeight="28px">
                {stats?.label || 'Investor'}
              </Text>
            </Box>
          </Box>
          <Box
            display="flex"
            padding="16px"
            flexDirection="column"
            alignItems="flex-start"
            gap="16px"
            alignSelf="stretch"
            background={'var(--Neutrals-2, #F6F6F6)'}
            borderRadius={'18px'}
          >
            {/* Inner Box */}
            <Box display="flex" alignItems="center" gap="10px">
              <BalanceIcon fill={'#39CA7F'} width={'36px'} height={'36px'} />
              <Text fs="36px" lineHeight={'52px'} letterSpacing={'-1.08px'}>
                {stats
                  ? Number(stats.balance).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })
                  : '28478'}
              </Text>
            </Box>
            <Box
              display="flex"
              padding="10px 10px 10px 12px"
              justifyContent="center"
              alignItems="center"
              gap="6px"
              borderRadius="10px"
              background="var(--Neutrals-1, #FFF)"
              boxShadow="0px 1px 3px 0px rgba(0, 0, 0, 0.19)"
              position={'relative'}
            >
              <Text
                fs="14px"
                fw={500}
                lineHeight={'18px'}
                paddingRight={'6px'}
                borderRight={' 1px solid rgba(0, 0, 0, 0.10)'}
              >
                {stats ? formatWalletAddress(stats.address) : '0x565.4343434'}
              </Text>
              <Image
                src={Copy}
                cursor={'pointer'}
                onClick={(e) => {
                  e.stopPropagation();
                  stats?.latest_transaction && copyToClipboard(stats.latest_transaction.transaction_hash);
                }}
              />
              {showCopyNotification && (
                <Box
                  position="absolute"
                  top="-30px"
                  left="50%"
                  transform="translateX(-50%)"
                  background="rgba(0, 0, 0, 0.8)"
                  color="white"
                  padding="8px 12px"
                  borderRadius="6px"
                  whiteSpace="nowrap"
                  fontFamily={'Poppins'}
                  fontSize="12px"
                  zIndex={1000}
                  boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
                >
                  Copied to clipboard
                </Box>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            padding="16px"
            gap="24px"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
            flex="1 0 0"
            alignSelf="stretch"
            borderRadius="18px"
            border="1px dashed rgba(0, 0, 0, 0.10)"
            background="var(--Neutrals-1, #FFF)"
            boxShadow="0px 9px 28px 8px rgba(0, 0, 0, 0.05),
                 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
                 0px 6px 16px 0px rgba(0, 0, 0, 0.08)"
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" alignSelf="stretch">
              <Text fs="16px" lineHeight={'28px'}>
                Last Transcation
              </Text>
              <SendReceiveBlock
                type={stats?.latest_transaction?.transfer_type?.toLowerCase() === 'send' ? 'send' : 'receive'}
              />
            </Box>
            <Box display="flex" alignItems="center" gap="10px">
              <BalanceIcon fill={'#39CA7F'} width={'20px'} height={'20px'} />
              <Box display="flex" alignItems="flex-end" gap="4px">
                <Text fs="20px" lineHeight={'20px'} letterSpacing={'0.6px'}>
                  {stats?.latest_transaction ? Number(stats.latest_transaction.amount).toFixed(2) : '123'}
                </Text>
                <Text
                  fs="14px"
                  lineHeight={'16px'}
                  letterSpacing={'-0.42px'}
                  fw={500}
                  c="var(--Text-Primary-description, #909090)"
                >
                  ${stats?.latest_transaction?.currency_symbol || 'DRINK'}
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              padding="12px"
              flexDirection="column"
              alignItems="flex-start"
              gap="12px"
              alignSelf="stretch"
              borderRadius="10px"
              background="var(--Neutrals-2, #F6F6F6)"
            >
              <Box display="flex" justifyContent="space-between" alignItems="center" alignSelf="stretch">
                <Text fs="14px" lineHeight={'18px'} fw={500}>
                  Today
                  {stats?.latest_transaction
                    ? formatBlockTime(stats.latest_transaction.block_time)
                    : 'Today, 5:44:17 PM'}
                </Text>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  gap={'8px'}
                  cursor={'pointer'}
                  onClick={() =>
                    stats?.latest_transaction &&
                    openInNewTab(getExplorerUrl(Number(chainId), stats.latest_transaction.transaction_hash))
                  }
                >
                  <Text fs="14px" lineHeight={'18px'} fw={500}>
                    {stats?.latest_transaction
                      ? formatWalletAddress(stats.latest_transaction.transaction_hash)
                      : '0x565.4343434'}
                  </Text>
                  <Image src={ExternalLink} />
                </Box>
              </Box>

              {/* Second Inner Box */}
              <Box
                display="flex"
                paddingTop="10px"
                justifyContent="space-between"
                alignItems="center"
                alignSelf="stretch"
                borderTop="1px dashed rgba(0, 0, 0, 0.10)"
              >
                <Text fs="14px" lineHeight={'18px'} fw={500}>
                  {stats?.latest_transaction && stats?.address
                    ? formatWalletAddress(getTransactionAddresses(stats.latest_transaction, stats.address).leftAddress)
                    : '0xvkc...d4zcd'}{' '}
                </Text>
                <Image src={Arrow} />
                <Text fs="14px" lineHeight={'18px'} fw={500}>
                  {stats?.latest_transaction && stats?.address
                    ? formatWalletAddress(getTransactionAddresses(stats.latest_transaction, stats.address).rightAddress)
                    : '0xAvc...Cc8cE'}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          padding="24px"
          flexDirection="column"
          alignItems="flex-end"
          gap="16px"
          flex="1"
          height={'100%'}
          borderRadius="36px"
          border="1px solid rgba(255, 255, 255, 0.10)"
          background="var(--Neutrals-1, #FFF)"
          boxShadow="0px 1px 7px 0px rgba(0, 0, 0, 0.08)"
        >
          <Text fs="16px" lineHeight={'28px'} textAlign={'left'} width={'100%'}>
            Assets
          </Text>
          <AssestComp walletAddress={walletAddress} />
        </Box>
      </Box>
      <Box
        display="flex"
        padding="24px"
        flexDirection="column"
        alignItems="center"
        gap="24px"
        alignSelf="stretch"
        borderRadius="36px"
        border="1px solid rgba(255, 255, 255, 0.10)"
        background="var(--Neutrals-1, #FFF)"
        boxShadow="0px 1px 7px 0px rgba(0, 0, 0, 0.08)"
      >
        <Flex justifyContent={'space-between'} width={'100%'} flex={'1 0 0'} alignItems={'center'}>
          <Text fs="20px" lineHeight={'28px'}>
            Transactions
          </Text>

          {/* Search and Add Section */}
          <Flex gap={'12px'} justify="space-between" align="center">
            <DatePicker />
            <InputGroup
              width="fit-content"
              padding="0px 20px 0px 12px"
              gap="10px"
              border="0.5px solid var(--Neutrals-4, #E9E9E9)"
              borderRadius="16px"
              background="var(--Neutrals-2, #F6F6F6)"
              alignItems="center"
            >
              <Image src={SearchIcon} alt="search" cursor="pointer" />
              <Input
                border="none"
                color="#000"
                padding={0}
                placeholder="Search by address or tag"
                background="transparent"
                _placeholder={{
                  color: 'var(--Text-Primary-description, #909090)',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
                _focus={{
                  border: 'none',
                  boxShadow: 'none',
                }}
              />
            </InputGroup>
          </Flex>
        </Flex>
        {/* Table */}
        <Table variant="unstyled">
          <Thead>
            <Tr
              display="flex"
              height="40px"
              padding="0px 12px"
              alignItems="center"
              borderRadius="12px"
              background="var(--Neutrals-2, #F6F6F6)"
            >
              <Th
                padding={0}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                From
              </Th>
              <Th
                padding="0px"
                paddingRight={'12px'}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                To
              </Th>
              <Th
                padding="0px"
                paddingRight={'12px'}
                flex="1"
                display="flex"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                justifyContent="space-between"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Amount
              </Th>
              <Th
                padding="0px"
                paddingRight={'12px'}
                flex="1"
                display="flex"
                justifyContent="space-between"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Type
              </Th>
              <Th
                padding="0px"
                paddingRight={'12px'}
                flex="1"
                display="flex"
                justifyContent="space-between"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Currency
                <FilterTag />
              </Th>
              <Th
                padding="0px"
                paddingRight={'12px'}
                flex="1"
                display="flex"
                justifyContent="space-between"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Tx Hash
              </Th>
              <Th
                padding="0px"
                paddingRight={'12px'}
                flex="1"
                display="flex"
                justifyContent="space-between"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Block Time
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {filteredAndPaginatedData.data.map((transaction) => (
              <Tr
                key={transaction.id}
                display="flex"
                alignItems="center"
                padding="12px"
                _hover={{ background: 'var(--Neutrals-1, #FFF)' }}
                borderBottom="1px solid rgba(246, 246, 246, 0.8)"
              >
                <Td padding="0px" flex="1" border="none">
                  <Text fs="14px" fw={500} lineHeight="22px" padding={0} textAlign={'left'}>
                    {formatWalletAddress(transaction.sender)}
                  </Text>
                </Td>
                <Td padding="0px" paddingRight={'12px'} flex="1" border="none">
                  <Text fs="14px" fw={500} lineHeight="22px" padding={0} textAlign={'left'}>
                    {formatWalletAddress(transaction.receiver)}
                  </Text>
                </Td>
                <Td padding="0px" paddingRight={'12px'} flex="1" border="none">
                  <Flex display="flex" alignItems="center" gap="8px">
                    <BalanceIcon fill="#39CA7F" />
                    <Text fs="14px" fw={500} lineHeight="22px" padding={0} textAlign={'left'}>
                      {Number(transaction.amount).toFixed(2)}
                    </Text>
                  </Flex>
                </Td>
                <Td padding="0px" paddingRight={'12px'} flex="1" border="none">
                  <Flex alignItems="center" gap="4px" borderRadius="4px" cursor="pointer">
                    <SendReceiveBlock type={getTransferType(transaction.transfer_type)} />
                  </Flex>
                </Td>
                <Td
                  flex="1"
                  border="none"
                  padding="0px"
                  paddingRight={'12px'}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text fs="14px" fw={500} lineHeight="22px" padding={0} textAlign={'left'}>
                    {transaction.currency_symbol}
                  </Text>
                </Td>
                <Td
                  flex="1"
                  border="none"
                  padding="0px"
                  paddingRight={'12px'}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text
                    fs="14px"
                    fw={500}
                    lineHeight="22px"
                    padding={0}
                    c="var(--Text-action, #4096FF)"
                    textAlign={'left'}
                  >
                    {formatWalletAddress(transaction.transaction_hash)}
                  </Text>
                </Td>
                <Td
                  flex="1"
                  border="none"
                  padding="0px"
                  paddingRight={'12px'}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text fs="14px" fw={500} lineHeight="22px" padding={0} textAlign={'left'}>
                    {formatBlockTime(transaction.block_time)}
                  </Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <TablePagination
          currentPage={currentPage}
          totalCount={filteredAndPaginatedData.totalCount}
          entriesPerPage={ITEMS_PER_PAGE}
          onPageChange={handlePageChange}
          hasNext={filteredAndPaginatedData.hasNext}
          hasPrevious={filteredAndPaginatedData.hasPrevious}
        />
      </Box>
    </Box>
  );
};

export default WalletInfo;
