// import { ArrowRightOutlined } from '@ant-design/icons';
// import { ChevronLeftIcon } from '@chakra-ui/icons';
// import {
//   Box,
//   Button,
//   Flex,
//   Heading,
//   Input,
//   Modal,
//   ModalBody,
//   ModalContent,
//   ModalOverlay,
//   Spinner,
//   Text,
//   Tooltip,
//   useToast,
//   VStack,
// } from '@chakra-ui/react';
// import React, { useEffect, useState } from 'react';
// import axios from '../api/axios';
// import HashTag from '../assets/images/pngs/Hashtag.png';
// import Inflow from '../assets/images/pngs/Inflow.png';
// import TokenAddressModal from '../components/modals/TokenAddressModal';
// import SettingsTable from '../components/SettingsTable';

// import Users from '../assets/images/pngs/Users.png';
// import UserTransactions from '../assets/images/pngs/UserTransactions.png';

// const Settings = () => {
//   const [currentView, setCurrentView] = useState('main');
//   const [pairAddress, setPairAddress] = useState('');
//   const [isTokenAddressModalOpen, setIsTokenAddressModalOpen] = useState(false);
//   const toast = useToast();
//   const [isUpdating, setIsUpdating] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [loadingStates, setLoadingStates] = useState({
//     inOutFlow: false,
//     userTransactions: false,
//     tokenHolders: false,
//   });
//   const [lastUpdateTimes, setLastUpdateTimes] = useState({
//     inOutFlow: null,
//     userTransactions: null,
//     tokenHolders: null,
//   });

//   useEffect(() => {
//     fetchLastUpdateTimes();
//   }, []);

//   const fetchLastUpdateTimes = async () => {
//     try {
//       const response = await axios.get('api/user/current/');
//       setLastUpdateTimes({
//         inOutFlow: response.data.data.last_inflow_outflow_update,
//         userTransactions: response.data.data.last_token_transactions_update,
//         tokenHolders: response.data.data.last_tokenholders_update,
//       });
//     } catch (error) {
//       console.error('Failed to fetch last update times:', error);
//     }
//   };

//   const handleViewChange = (view: React.SetStateAction<string>) => {
//     setCurrentView(view);
//   };

//   const handleAddPairAddress = async () => {
//     try {
//       const response = await axios.patch(
//         '/api/user/account/',
//         { pair_address: pairAddress },
//         {
//           headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
//         },
//       );

//       if (response.status === 200) {
//         toast({
//           title: 'Success',
//           description: 'Pair address updated successfully',
//           status: 'success',
//           duration: 5000,
//           isClosable: true,
//         });
//         setPairAddress('');
//       }
//     } catch (error) {
//       toast({
//         title: 'Error',
//         description: 'Failed to update pair address',
//         status: 'error',
//         duration: 5000,
//         isClosable: true,
//       });
//     }
//   };

//   const handleTokenAddressSuccess = () => {
//     setCurrentView('main');
//   };

//   const handleUpdate = async (endpoint: string, stateKey: keyof typeof loadingStates) => {
//     if (loadingStates[stateKey]) return;

//     setLoadingStates((prev) => ({ ...prev, [stateKey]: true }));
//     setIsUpdating(true);

//     try {
//       // Step 1: Trigger the update
//       await axios.post(
//         `/api/user/${endpoint}`,
//         {},
//         {
//           headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
//         },
//       );

//       let attempts = 0;
//       const maxAttempts = 10;

//       const pollData = async () => {
//         if (attempts >= maxAttempts) {
//           if (pollInterval) {
//             clearInterval(pollInterval);
//           }
//           setLoadingStates((prev) => ({ ...prev, [stateKey]: false }));
//           setIsUpdating(false);
//           toast({
//             title: 'Update timed out',
//             description: 'Please try again',
//             status: 'warning',
//             isClosable: true,
//           });
//           return;
//         }

//         try {
//           const response = await axios.get('api/user/current/');

//           if (response.status === 200) {
//             setLastUpdateTimes((prev) => ({
//               ...prev,
//               [stateKey]: response.data.data[`last_${stateKey}_update`],
//             }));

//             clearInterval(pollInterval);
//             setLoadingStates((prev) => ({ ...prev, [stateKey]: false }));
//             setIsUpdating(false);

//             toast({
//               title: 'Data updated successfully',
//               status: 'success',
//               duration: 3000,
//               isClosable: true,
//             });
//           }
//         } catch (error) {
//           attempts++;
//         }
//       };

//       const pollInterval = setInterval(pollData, 2000); // Poll every 2 seconds

//       // Cleanup after 30 seconds
//       setTimeout(() => {
//         clearInterval(pollInterval);
//         if (loadingStates[stateKey]) {
//           setLoadingStates((prev) => ({ ...prev, [stateKey]: false }));
//           setIsUpdating(false);
//           toast({
//             title: 'Update timed out',
//             description: 'Please try again',
//             status: 'warning',
//             isClosable: true,
//           });
//         }
//       }, 30000);
//     } catch (error) {
//       console.error('Error during update:', error);
//       toast({
//         title: 'Error updating data',
//         status: 'error',
//         isClosable: true,
//       });
//       setLoadingStates((prev) => ({ ...prev, [stateKey]: false }));
//       setIsUpdating(false);
//     }
//   };

//   const formatLastUpdateTime = (timestamp: string | null) => {
//     if (!timestamp) return 'Never';
//     const date = new Date(timestamp);
//     return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
//   };

//   const BackButton = () => (
//     <Flex
//       alignItems="center"
//       mb={4}
//       cursor="pointer"
//       onClick={() => handleViewChange('main')}
//       bg="white"
//       p={2}
//       borderRadius="full"
//       boxShadow="md"
//       width="fit-content"
//     >
//       <ChevronLeftIcon color={'black'} />
//       <Text ml={2} fontWeight="medium" color={'black'}>
//         Back to Settings
//       </Text>
//     </Flex>
//   );

//   const boxStyles = {
//     display: 'flex',
//     padding: '12px', // Decreased padding
//     flexDirection: 'column' as const,
//     alignItems: 'flex-start',
//     gap: '12px', // Decreased gap
//     borderRadius: '20px',
//     border: '1px solid rgba(0, 0, 0, 0.06)',
//     background: '#FFF',
//     boxShadow:
//       '0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)',
//     height: 'auto',
//   };

//   const tooltipStyles = {
//     backgroundColor: 'rgba(255, 255, 255, 0.8)',
//     color: '#000000',
//     borderRadius: '8px',
//     padding: '8px 12px',
//     fontSize: '12px',
//     fontWeight: '400',
//     boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)',
//     backdropFilter: 'blur(10px)',
//   };

//   return (
//     <Box p={4}>
//       {' '}
//       {currentView === 'main' ? (
//         <Flex gap={4} align="stretch">
//           {' '}
//           <VStack spacing={4} align="stretch" flex={0.75}>
//             {' '}
//             <Box {...boxStyles}>
//               <Heading
//                 as="h1"
//                 fontSize="24px"
//                 fontWeight="500"
//                 fontStyle="normal"
//                 color="#000000E0"
//                 fontFamily="Poppins"
//                 lineHeight="24px"
//               >
//                 Settings
//               </Heading>
//               <Text fontSize="md" color="#909090">
//                 You can always change the items you have set in here.
//               </Text>

//               <VStack spacing={2} align="stretch" width="100%">
//                 <Button
//                   onClick={() => setIsTokenAddressModalOpen(true)}
//                   variant="unstyled"
//                   width="100%"
//                   height="auto"
//                   p={0}
//                 >
//                   <Flex alignItems="center" width="100%">
//                     <Flex
//                       alignItems="center"
//                       justifyContent="center"
//                       width="40px"
//                       height="40px"
//                       borderRadius="50%"
//                       mr={3}
//                     >
//                       <img src={HashTag} alt="HashTag" width="20px" height="20px" />
//                     </Flex>
//                     <Text
//                       flex={1}
//                       textAlign="left"
//                       fontFamily="Poppins"
//                       fontSize="14px"
//                       fontWeight={400}
//                       color="#000000E0"
//                     >
//                       Add your token address
//                     </Text>
//                     <ArrowRightOutlined style={{ color: '#d6d6d6' }} />
//                   </Flex>
//                 </Button>
//                 <Button
//                   onClick={() => handleViewChange('inOutFlow')}
//                   variant="unstyled"
//                   width="100%"
//                   height="auto"
//                   p={0}
//                 >
//                   <Flex alignItems="center" width="100%">
//                     <Flex
//                       alignItems="center"
//                       justifyContent="center"
//                       width="40px"
//                       height="40px"
//                       borderRadius="50%"
//                       mr={3}
//                     >
//                       <img src={Inflow} alt="Inflow" width="20px" height="20px" />
//                     </Flex>
//                     <Text
//                       flex={1}
//                       textAlign="left"
//                       fontFamily="Poppins"
//                       fontSize="14px"
//                       fontWeight={400}
//                       color="#000000E0"
//                     >
//                       Manage your Inflow and Outflow Addresses
//                     </Text>
//                     <ArrowRightOutlined style={{ color: '#d6d6d6' }} />
//                   </Flex>
//                 </Button>
//               </VStack>
//             </Box>
//             <Box {...boxStyles}>
//               <Heading as="h2" size="md">
//                 Add your pair address
//               </Heading>
//               <Text
//                 color={'#000000E0'}
//                 fontFamily="Poppins"
//                 fontSize="24px"
//                 lineHeight={'24px'}
//                 fontWeight={500}
//                 fontStyle="normal"
//               >
//                 Add your pair address
//               </Text>
//               <Text
//                 color={'#000000E0'}
//                 fontFamily="Poppins"
//                 fontSize="14px"
//                 lineHeight={'22px'}
//                 fontWeight={400}
//                 fontStyle="normal"
//               >
//                 Pair Address
//               </Text>
//               <Input
//                 placeholder="Enter Pair Address"
//                 color={'#000000E0'}
//                 value={pairAddress}
//                 onChange={(e) => setPairAddress(e.target.value)}
//                 width="100%"
//               />
//               <Button background={'#2d8dfb'} colorScheme="blue" onClick={handleAddPairAddress} width="100%">
//                 Add
//               </Button>
//             </Box>
//           </VStack>
//           {/* New Box for Update Data */}
//           <Box {...boxStyles} width={'620px'} height={'280px'}>
//             {' '}
//             {/* Changed width to 50% */}
//             <Heading
//               as="h1"
//               fontSize="18px"
//               fontWeight="500"
//               fontStyle="normal"
//               color="#000000E0"
//               fontFamily="Poppins"
//               lineHeight="24px"
//             >
//               Update Data
//             </Heading>
//             <Text textAlign="left" fontFamily="Poppins" fontSize="12px" fontWeight={400} color="#000000E0">
//               Quickly update your information, and stay up to date
//             </Text>
//             <VStack spacing={2} align="stretch" width="100%">
//               <Flex alignItems="center" width="100%">
//                 <Flex alignItems="center" justifyContent="center" width="40px" height="40px" borderRadius="50%" mr={3}>
//                   <img src={Inflow} alt="HashTag" width="20px" height="20px" />
//                 </Flex>
//                 <Text flex={1} textAlign="left" fontFamily="Poppins" fontSize="14px" fontWeight={400} color="#000000E0">
//                   Update In-Flow & Out-Flow Transactions
//                 </Text>

//                 <Tooltip
//                   label={`Last updated: ${formatLastUpdateTime(lastUpdateTimes.inOutFlow)}`}
//                   hasArrow
//                   placement="top"
//                   {...tooltipStyles}
//                 >
//                   <Button
//                     background={'#2d8dfb'}
//                     colorScheme="blue"
//                     onClick={() => handleUpdate('trigger-update-user-inflow-outflow/', 'inOutFlow')}
//                     isLoading={loadingStates.inOutFlow}
//                   >
//                     Update
//                   </Button>
//                 </Tooltip>
//               </Flex>

//               <Flex alignItems="center" width="100%">
//                 <Flex alignItems="center" justifyContent="center" width="40px" height="40px" borderRadius="50%" mr={3}>
//                   <img src={UserTransactions} alt="HashTag" width="20px" height="20px" />
//                 </Flex>
//                 <Text flex={1} textAlign="left" fontFamily="Poppins" fontSize="14px" fontWeight={400} color="#000000E0">
//                   Update User Transactions
//                 </Text>

//                 <Tooltip
//                   label={`Last updated: ${formatLastUpdateTime(lastUpdateTimes.userTransactions)}`}
//                   hasArrow
//                   placement="top"
//                   {...tooltipStyles}
//                 >
//                   <Button
//                     background={'#2d8dfb'}
//                     colorScheme="blue"
//                     onClick={() => handleUpdate('trigger-update-user-token-transactions/', 'userTransactions')}
//                     isLoading={loadingStates.userTransactions}
//                   >
//                     Update
//                   </Button>
//                 </Tooltip>
//               </Flex>

//               <Flex alignItems="center" width="100%">
//                 <Flex alignItems="center" justifyContent="center" width="40px" height="40px" borderRadius="50%" mr={3}>
//                   <img src={Users} alt="HashTag" width="20px" height="20px" />
//                 </Flex>
//                 <Text flex={1} textAlign="left" fontFamily="Poppins" fontSize="14px" fontWeight={400} color="#000000E0">
//                   Update Token Holders
//                 </Text>

//                 <Tooltip
//                   label={`Last updated: ${formatLastUpdateTime(lastUpdateTimes.tokenHolders)}`}
//                   hasArrow
//                   placement="top"
//                   {...tooltipStyles}
//                 >
//                   <Button
//                     background={'#2d8dfb'}
//                     colorScheme="blue"
//                     onClick={() => handleUpdate('trigger-update-user-tokenholders/', 'tokenHolders')}
//                     isLoading={loadingStates.tokenHolders}
//                   >
//                     Update
//                   </Button>
//                 </Tooltip>
//               </Flex>
//             </VStack>
//           </Box>
//         </Flex>
//       ) : (
//         <Box>
//           <BackButton />
//           {currentView === 'inOutFlow' && <SettingsTable />}
//         </Box>
//       )}
//       <TokenAddressModal
//         isShowModal={isTokenAddressModalOpen}
//         setIsShowModal={setIsTokenAddressModalOpen}
//         onSuccess={handleTokenAddressSuccess}
//       />
//       <Modal
//         isOpen={isUpdating}
//         isCentered
//         closeOnOverlayClick={false}
//         closeOnEsc={false}
//         onClose={() => {
//           setIsUpdating(false);
//           setLoadingStates({
//             inOutFlow: false,
//             userTransactions: false,
//             tokenHolders: false,
//           });
//         }}
//       >
//         <ModalOverlay />
//         <ModalContent bg="white" borderRadius="md" p={6} maxWidth="300px">
//           <ModalBody>
//             <Flex direction="column" align="center" justify="center" height="100%">
//               <Text fontWeight="medium" textAlign="center" mb={4} color={'#1f1f1f'}>
//                 Fetching Latest Data
//               </Text>
//               <Spinner size="xl" color="blue.500" />
//               <Text fontSize="sm" textAlign="center" color={'#1f1f1f'} mt={4}>
//                 This may take some time, please be patient
//               </Text>
//             </Flex>
//           </ModalBody>
//         </ModalContent>
//       </Modal>
//     </Box>
//   );
// };
// export default Settings;

import {
  Box,
  Flex,
  Image,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import axios from '../api/axios';
import Frame1 from '../assets/images/svgs/Frame1.svg';
import Frame2 from '../assets/images/svgs/Frame2.svg';
import Frame3 from '../assets/images/svgs/Frame3.svg';
import Frame4 from '../assets/images/svgs/Frame4.svg';
import Frame5 from '../assets/images/svgs/Frame5.svg';
import Frame6 from '../assets/images/svgs/Frame6.svg';
import RefreshIcon from '../assets/images/svgs/RefreshIcon.svg';
import SearchIcon from '../assets/images/svgs/SearchIcon.svg';
import Text from '../components/common/Text';
import TokenAddressModal from '../components/modals/TokenAddressModal';

interface NotificationCount {
  total_unread: number;
  by_category: Record<string, number>;
}

const Settings = () => {
  const [selected, setSelected] = useState('update');
  const [isTokenAddressModalOpen, setIsTokenAddressModalOpen] = useState(false);
  const [pairAddress, setPairAddress] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [loadingStates, setLoadingStates] = useState({
    inOutFlow: false,
    userTransactions: false,
    tokenHolders: false,
  });
  const [lastUpdateTimes, setLastUpdateTimes] = useState({
    inOutFlow: null,
    userTransactions: null,
    tokenHolders: null,
  });
  const toast = useToast();

  useEffect(() => {
    fetchLastUpdateTimes();
  }, []);

  const fetchLastUpdateTimes = async () => {
    try {
      const response = await axios.get('api/user/current/');
      setLastUpdateTimes({
        inOutFlow: response.data.data.last_inflow_outflow_update,
        userTransactions: response.data.data.last_token_transactions_update,
        tokenHolders: response.data.data.last_tokenholders_update,
      });
    } catch (error) {
      console.error('Failed to fetch last update times:', error);
    }
  };

  const handleUpdate = async (endpoint: string, stateKey: keyof typeof loadingStates) => {
    if (loadingStates[stateKey]) return;

    setLoadingStates((prev) => ({ ...prev, [stateKey]: true }));
    setIsUpdating(true);

    try {
      await axios.post(
        `/api/user/${endpoint}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );

      let attempts = 0;
      const maxAttempts = 10;

      const pollData = async () => {
        if (attempts >= maxAttempts) {
          if (pollInterval) clearInterval(pollInterval);
          setLoadingStates((prev) => ({ ...prev, [stateKey]: false }));
          setIsUpdating(false);
          toast({
            title: 'Update timed out',
            description: 'Please try again',
            status: 'warning',
            isClosable: true,
          });
          return;
        }

        try {
          const response = await axios.get('api/user/current/');
          if (response.status === 200) {
            // Map the API response keys to our state keys
            const keyMapping = {
              inOutFlow: 'last_inflow_outflow_update',
              userTransactions: 'last_token_transactions_update',
              tokenHolders: 'last_tokenholders_update',
            };

            // Update the specific timestamp for the updated item
            const newTimestamp = response.data.data[keyMapping[stateKey]];

            if (newTimestamp) {
              setLastUpdateTimes((prev) => ({
                ...prev,
                [stateKey]: newTimestamp,
              }));
            }

            clearInterval(pollInterval);
            setLoadingStates((prev) => ({ ...prev, [stateKey]: false }));
            setIsUpdating(false);
            toast({
              title: 'Data updated successfully',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          }
        } catch (error) {
          attempts++;
        }
      };

      const pollInterval = setInterval(pollData, 2000);

      // Add cleanup timeout
      setTimeout(() => {
        clearInterval(pollInterval);
        if (loadingStates[stateKey]) {
          setLoadingStates((prev) => ({ ...prev, [stateKey]: false }));
          setIsUpdating(false);
          toast({
            title: 'Update timed out',
            description: 'Please try again',
            status: 'warning',
            isClosable: true,
          });
        }
      }, 30000);
    } catch (error) {
      console.error('Error during update:', error);
      toast({
        title: 'Error updating data',
        status: 'error',
        isClosable: true,
      });
      setLoadingStates((prev) => ({ ...prev, [stateKey]: false }));
      setIsUpdating(false);
    }
  };

  const formatLastUpdateTime = (timestamp: string | null) => {
    if (!timestamp) return 'Never updated';
    const date = new Date(timestamp);
    return `Last updated - ${date.toLocaleTimeString()} ${date.toLocaleDateString()}`;
  };

  const handleTokenAddressSuccess = () => {
    setSelected('update');
  };

  const handleAddPairAddress = async () => {
    try {
      const response = await axios.patch(
        '/api/user/account/',
        { pair_address: pairAddress },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'Pair address updated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setPairAddress('');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update pair address',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateItems = [
    {
      icon: Frame1,
      title: 'Update In-Flow & Out-Flow Transactions',
      lastUpdated: formatLastUpdateTime(lastUpdateTimes.inOutFlow),
      endpoint: 'trigger-update-user-inflow-outflow/',
      stateKey: 'inOutFlow',
    },
    {
      icon: Frame2,
      title: 'Update User Transactions',
      lastUpdated: formatLastUpdateTime(lastUpdateTimes.userTransactions),
      endpoint: 'trigger-update-user-token-transactions/',
      stateKey: 'userTransactions',
    },
    {
      icon: Frame3,
      title: 'Update Token Holders',
      lastUpdated: formatLastUpdateTime(lastUpdateTimes.tokenHolders),
      endpoint: 'trigger-update-user-tokenholders/',
      stateKey: 'tokenHolders',
    },
  ];

  const manageItems = [
    {
      icon: Frame4,
      title: 'Add your token address',
      actionButton: 'Add Address',
      onClick: () => setIsTokenAddressModalOpen(true),
    },
    {
      icon: Frame5,
      title: 'Add your pair address',
      actionButton: 'Add Address',
      onClick: handleAddPairAddress,
    },
    {
      icon: Frame6,
      title: 'Manage your Inflow and Outflow Addresses',
      actionButton: 'Manage',
      onClick: () => setSelected('inOutFlow'),
    },
  ];

  // Continue with the rest of your rendering code...
  // [Previous return JSX remains the same until the updateItems mapping]

  const renderUpdateTable = () => {
    return (
      <>
        {updateItems.map((item, index) => (
          <Box
            key={index}
            display="flex"
            padding="0px 12px 20px 12px"
            alignItems="center"
            alignSelf="stretch"
            borderBottom="1px solid rgba(246, 246, 246, 0.00)"
          >
            <Box display="flex" paddingRight="24px" justifyContent="space-between" alignItems="center" flex="1 0 0">
              <Box display="flex" alignItems="center" gap="10px">
                <Box
                  display="flex"
                  padding="10px"
                  alignItems="center"
                  gap="10px"
                  borderRadius="100px"
                  background="var(--Neutrals-2, #F6F6F6)"
                >
                  <Image src={item.icon} alt={item.title} width="24px" height="24px" />
                </Box>
                <Text fs="14px" fw={500} lineHeight={'22px'}>
                  {item.title}
                </Text>
              </Box>
              <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={500} lineHeight={'22px'}>
                {item.lastUpdated}
              </Text>
            </Box>
            <Flex
              padding="6px 12px 6px 6px"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              gap="8px"
              borderRadius="22px"
              background="#000"
              cursor="pointer"
              position="relative"
              onClick={() => handleUpdate(item.endpoint, item.stateKey as keyof typeof loadingStates)}
              opacity={loadingStates[item.stateKey as keyof typeof loadingStates] ? 0.7 : 1}
            >
              <Image src={RefreshIcon} alt="refresh" />
              <Text
                c="var(--Button-colorTextLightSolid, #FFF)"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="22px"
              >
                {loadingStates[item.stateKey as keyof typeof loadingStates] ? 'Updating...' : 'Refresh'}
              </Text>
            </Flex>
          </Box>
        ))}
      </>
    );
  };

  const renderManageTable = () => {
    return (
      <>
        {manageItems.map((item, index) => (
          <Box
            key={index}
            display="flex"
            padding="0px 12px 20px 12px"
            alignItems="center"
            alignSelf="stretch"
            borderBottom="1px solid rgba(246, 246, 246, 0.00)"
          >
            <Box display="flex" paddingRight="24px" justifyContent="space-between" alignItems="center" flex="1 0 0">
              <Box display="flex" alignItems="center" gap="10px">
                <Box
                  display="flex"
                  padding="10px"
                  alignItems="center"
                  gap="10px"
                  borderRadius="100px"
                  background="var(--Neutrals-2, #F6F6F6)"
                >
                  <Image src={item.icon} width="24px" height="24px" />
                </Box>
                <Text fs="14px" fw={500} lineHeight={'22px'}>
                  {item.title}
                </Text>
              </Box>

              <Box
                display="flex"
                padding="6px var(--Button-paddingContentHorizontal, 16px)"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap="8px"
                borderRadius="16px"
                border="1px solid rgba(0, 0, 0, 0.15)"
                background="var(--Button-colorBgContainer, #FFF)"
                cursor="pointer"
                onClick={item.onClick}
              >
                <Text c="rgba(0, 0, 0, 0.88)" fs="14px" fw="500" lineHeight="22px">
                  {item.actionButton}
                </Text>
              </Box>
            </Box>
          </Box>
        ))}
      </>
    );
  };

  return (
    <Box
      display="flex"
      padding="14px 24px 40px 24px"
      flexDirection="column"
      alignItems="flex-start"
      gap="56px"
      flex="1 0 0"
      alignSelf="stretch"
    >
      {/* Header */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="flex-start"
        gap="16px"
        alignSelf="stretch"
      >
        <Text fs="36px" lineHeight={'28px'}>
          Settings
        </Text>
        <Text fs="14px" lineHeight={'22px'} fw={400}>
          Customize your experience. Manage updates for transactions, addresses, and token holders with ease.
        </Text>
      </Box>

      {/* Main Content */}
      <Box
        display="flex"
        padding="24px"
        flexDirection="column"
        alignItems="center"
        gap="24px"
        alignSelf="stretch"
        borderRadius="36px"
        border="1px solid rgba(255, 255, 255, 0.10)"
        background="var(--Neutrals-1, #FFF)"
        boxShadow="0px 1px 7px 0px rgba(0, 0, 0, 0.08)"
      >
        {/* Switcher */}
        <Flex width={'100%'} justifyContent={'space-between'}>
          <Box
            display="flex"
            padding="5px"
            alignItems="center"
            gap="10px"
            borderRadius="14px"
            background="var(--Neutrals-2, #F6F6F6)"
          >
            <Box
              display="flex"
              padding="6px 12px"
              justifyContent="center"
              alignItems="center"
              gap="10px"
              borderRadius="10px"
              background={selected === 'update' ? '#FFF' : 'transparent'}
              boxShadow={selected === 'update' ? '0px 1px 3px 0px rgba(0, 0, 0, 0.19)' : 'none'}
              cursor="pointer"
              onClick={() => setSelected('update')}
            >
              <Text
                c={
                  selected === 'update'
                    ? 'var(--Text-Primary-default, #000)'
                    : 'var(--Text-Primary-description, #909090)'
                }
                fw={500}
                fs="14px"
                lineHeight={'22px'}
              >
                Update data
              </Text>
            </Box>
            <Box
              display="flex"
              padding="6px 12px"
              justifyContent="center"
              alignItems="center"
              gap="10px"
              borderRadius="10px"
              background={selected === 'manage' ? '#FFF' : 'transparent'}
              boxShadow={selected === 'manage' ? '0px 1px 3px 0px rgba(0, 0, 0, 0.19)' : 'none'}
              cursor="pointer"
              onClick={() => setSelected('manage')}
            >
              <Text
                c={
                  selected === 'manage'
                    ? 'var(--Text-Primary-default, #000)'
                    : 'var(--Text-Primary-description, #909090)'
                }
                fw={500}
                fs="14px"
                lineHeight={'22px'}
              >
                Manage addresses
              </Text>
            </Box>
          </Box>
          <InputGroup
            width="fit-content"
            padding="0px 20px 0px 12px"
            gap="10px"
            border="0.5px solid var(--Neutrals-4, #E9E9E9)"
            borderRadius="16px"
            background="var(--Neutrals-2, #F6F6F6)"
            alignItems="center"
          >
            <Image src={SearchIcon} alt="search" cursor="pointer" />
            <Input
              border="none"
              color="#000"
              padding={0}
              placeholder="Find a Setting"
              background="transparent"
              _placeholder={{
                color: 'var(--Text-Primary-description, #909090)',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: '500',
              }}
              _focus={{
                border: 'none',
                boxShadow: 'none',
              }}
            />
          </InputGroup>
        </Flex>

        {/* Conditional Table Rendering */}
        {selected === 'update' ? renderUpdateTable() : renderManageTable()}
      </Box>

      {/* Modals */}
      <TokenAddressModal
        isShowModal={isTokenAddressModalOpen}
        setIsShowModal={setIsTokenAddressModalOpen}
        onSuccess={handleTokenAddressSuccess}
      />

      <Modal
        isOpen={isUpdating}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
        onClose={() => {
          setIsUpdating(false);
          setLoadingStates({
            inOutFlow: false,
            userTransactions: false,
            tokenHolders: false,
          });
        }}
      >
        <ModalOverlay />
        <ModalContent bg="white" borderRadius="md" p={6} maxWidth="300px">
          <ModalBody>
            <Flex direction="column" align="center" justify="center" height="100%">
              <Text fontWeight="medium" textAlign="center" mb={4} color={'#1f1f1f'}>
                Fetching Latest Data
              </Text>
              <Spinner size="xl" color="blue.500" />
              <Text fontSize="sm" textAlign="center" color={'#1f1f1f'} mt={4}>
                This may take some time, please be patient
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Settings;
