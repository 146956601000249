import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const CalendarIcon = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" fill="none" {...props}>
    <path d="M13.9586 1.66667C13.9586 1.32501 13.6752 1.04167 13.3336 1.04167C12.9919 1.04167 12.7086 1.32501 12.7086 1.66667V2.91667H7.2919V1.66667C7.2919 1.32501 7.00857 1.04167 6.6669 1.04167C6.32523 1.04167 6.0419 1.32501 6.0419 1.66667V2.96667C3.7919 3.175 2.70023 4.51667 2.53357 6.50834C2.5169 6.75 2.7169 6.95001 2.95023 6.95001H17.0502C17.2919 6.95001 17.4919 6.74167 17.4669 6.50834C17.3002 4.51667 16.2086 3.175 13.9586 2.96667V1.66667Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33333 8.19987C2.875 8.19987 2.5 8.57487 2.5 9.03321V14.1665C2.5 16.6665 3.75 18.3332 6.66667 18.3332H13.3333C16.25 18.3332 17.5 16.6665 17.5 14.1665V9.03321C17.5 8.57487 17.125 8.19987 16.6667 8.19987H3.33333ZM7.4 15.3499C7.3 15.3915 7.19167 15.4165 7.08333 15.4165C6.975 15.4165 6.86667 15.3915 6.76667 15.3499C6.66667 15.3082 6.575 15.2499 6.49167 15.1749C6.34167 15.0165 6.25 14.7999 6.25 14.5832C6.25 14.3665 6.34167 14.1499 6.49167 13.9915C6.575 13.9165 6.66667 13.8582 6.76667 13.8165C6.96667 13.7332 7.2 13.7332 7.4 13.8165C7.5 13.8582 7.59167 13.9165 7.675 13.9915C7.825 14.1499 7.91667 14.3665 7.91667 14.5832C7.91667 14.7999 7.825 15.0165 7.675 15.1749C7.59167 15.2499 7.5 15.3082 7.4 15.3499ZM7.675 12.2582C7.59167 12.3332 7.5 12.3915 7.4 12.4332C7.3 12.4749 7.19167 12.4999 7.08333 12.4999C6.975 12.4999 6.86667 12.4749 6.76667 12.4332C6.66667 12.3915 6.575 12.3332 6.49167 12.2582C6.41667 12.1749 6.35833 12.0832 6.31667 11.9832C6.275 11.8832 6.25 11.7749 6.25 11.6665C6.25 11.5582 6.275 11.4499 6.31667 11.3499C6.35833 11.2499 6.41667 11.1582 6.49167 11.0749C6.575 10.9999 6.66667 10.9415 6.76667 10.8999C6.96667 10.8165 7.2 10.8165 7.4 10.8999C7.5 10.9415 7.59167 10.9999 7.675 11.0749C7.75 11.1582 7.80833 11.2499 7.85 11.3499C7.89167 11.4499 7.91667 11.5582 7.91667 11.6665C7.91667 11.7749 7.89167 11.8832 7.85 11.9832C7.80833 12.0832 7.75 12.1749 7.675 12.2582ZM10.3167 12.4332C10.2167 12.4749 10.1083 12.4999 10 12.4999C9.89167 12.4999 9.78333 12.4749 9.68333 12.4332C9.58333 12.3915 9.49167 12.3332 9.40833 12.2582C9.25833 12.0999 9.16667 11.8832 9.16667 11.6665C9.16667 11.4499 9.25833 11.2332 9.40833 11.0749C9.49167 10.9999 9.58333 10.9415 9.68333 10.8999C9.88333 10.8082 10.1167 10.8082 10.3167 10.8999C10.4167 10.9415 10.5083 10.9999 10.5917 11.0749C10.7417 11.2332 10.8333 11.4499 10.8333 11.6665C10.8333 11.8832 10.7417 12.0999 10.5917 12.2582C10.5083 12.3332 10.4167 12.3915 10.3167 12.4332Z"
    />
  </Icon>
);
