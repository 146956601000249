import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import Home from '../../pages/Home';
import HomeEmpty from '../../pages/HomeEmpty';

interface UserData {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  token_address: string;
  pair_address: string | null;
  coin_gecko_id: string | null;
  is_project_funded: boolean;
  project_name: string;
  last_inflow_outflow_update: string;
  last_tokenholders_update: string;
  last_token_transactions_update: string;
  blockchain_network: {
    id: number;
    chain_id: number;
    network_name: string;
    rpc_url: string;
    currency_symbol: string;
  };
  cmc_id: string | null;
}

const TokenDashboardWrapper = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0); // Add refresh key
  const navigate = useNavigate();

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        navigate('/');
        return;
      }

      const response = await axios.get<{ data: UserData }>('api/user/current/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // Add cache-busting parameter
        params: {
          _t: new Date().getTime(),
        },
      });

      console.log('API Response:', response.data.data);
      console.log('CMC ID:', response.data.data.cmc_id);
      setUserData(response.data.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [navigate, refreshKey]); // Add refreshKey to dependencies

  // Add refresh function
  const refreshData = () => {
    setIsLoading(true);
    setRefreshKey((prev) => prev + 1);
  };

  if (isLoading) {
    return null;
  }

  // Debugging log
  console.log('Current userData:', userData);
  console.log('CMC ID check:', !userData?.cmc_id || userData.cmc_id.trim() === '');

  if (!userData?.cmc_id || userData.cmc_id.trim() === '') {
    return <HomeEmpty />;
  }

  return <Home />;
};

export default TokenDashboardWrapper;
