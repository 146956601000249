import { Box, Flex, Image, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import Alert from '../../assets/images/svgs/Alert.svg';
import Leaderboard from '../../assets/images/svgs/Leaderboard.svg';
import NotificationIcon from '../../assets/images/svgs/notification.svg';
import TokenHolder from '../../assets/images/svgs/TokenHolder.svg';
import Transaction from '../../assets/images/svgs/Transaction.svg';
import Update from '../../assets/images/svgs/Updates.svg';

import { Dots } from '../../Icons/Dot';
import Text from './Text';

interface NotificationRelatedObject {
  type: string;
  id: string;
  str: string;
}

interface Notification {
  id: string;
  category: string;
  category_name: string;
  title: string;
  message: string;
  priority: string;
  status: 'READ' | 'UNREAD';
  created_at: string;
  read_at: string | null;
  data: any;
  related_object?: NotificationRelatedObject;
}

interface NotificationCount {
  total_unread: number;
  by_category: Record<string, number>;
}

interface NotificationItemProps {
  notification: Notification;
  onClick: (notification: Notification) => void;
}

interface GroupedNotifications {
  date: string;
  notifications: Notification[];
}

const NotificationItem: React.FC<NotificationItemProps> = ({ notification, onClick }) => {
  const hasAddress = notification.message.includes('0x');
  const [messageStart, address] = hasAddress
    ? notification.message.split(/(0x[a-fA-F0-9]+)/)
    : [notification.message, ''];

  const formatTimestamp = (timestamp: string) => {
    try {
      const date = new Date(timestamp);
      const now = new Date();
      const diffInMinutes = Math.floor((now.getTime() - date.getTime()) / (1000 * 60));

      if (diffInMinutes < 60) {
        return `${diffInMinutes} minutes ago`;
      } else if (diffInMinutes < 1440) {
        const hours = Math.floor(diffInMinutes / 60);
        return `${hours} hours ago`;
      } else {
        return date.toLocaleDateString();
      }
    } catch (error) {
      return timestamp;
    }
  };

  // Add this inside NotificationItem component before the return statement
  const getNotificationStyle = (category: string) => {
    switch (category) {
      case 'Transactions':
        return { image: Transaction, bgColor: ' var(--blue-1, #E6F4FF)' };
      case 'TokenHolder':
        return { image: TokenHolder, bgColor: 'var(--green-1, #EFFBF5)' };
      case 'Alerts':
        return { image: Alert, bgColor: 'var(--red-1, #FFF1F0)' };
      case 'Update':
        return { image: Update, bgColor: 'var(--orange-1, #FFF7E6)' };
      case 'Leaderboards':
        return { image: Leaderboard, bgColor: 'var(--purple-1, #F9F0FF)' };
      default:
        return { image: Update, bgColor: '#FFF7E6' };
    }
  };

  const formatWalletAddress = (address: string) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  return (
    <Flex
      display="flex"
      alignItems="center"
      gap="10px"
      flex="1 0 0"
      cursor="pointer"
      padding="12px"
      onClick={() => onClick(notification)}
      _hover={{ background: 'var(--Neutrals-3, #F7F7F7)' }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '40px',
          height: '40px',
          borderRadius: '100px',
          background: getNotificationStyle(notification.category_name).bgColor,
        }}
      >
        <Image src={getNotificationStyle(notification.category_name).image} width="24px" height="24px" />
      </div>
      <Box flex={1} display="flex" flexDir="column" gap="4px">
        <Text fs="14px" lineHeight="22px" textAlign={'left'}>
          {notification.category_name}
        </Text>

        {hasAddress ? (
          <>
            <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={500} lineHeight="20px" textAlign="left">
              {messageStart}
            </Text>
            <Text
              c="var(--Text-Primary-description, #909090)"
              fs="14px"
              lineHeight="20px"
              fw={500}
              textAlign="left"
              fontFamily="monospace"
            >
              {formatWalletAddress(address)}
            </Text>
          </>
        ) : (
          <Text c="var(--Text-Primary-description, #909090)" fs="14px" lineHeight="20px" fw={500} textAlign="left">
            {notification.message}
          </Text>
        )}

        <Text fontSize="14px" fw={500} lineHeight={'22px'} color="var(--Text-action, #4096FF)" textAlign={'left'}>
          {formatTimestamp(notification.created_at)}
        </Text>
      </Box>

      <Flex align="center" gap="8px">
        <Dots color={notification.status === 'UNREAD' ? '#FF4D4F' : '#909090'} />
      </Flex>
    </Flex>
  );
};

const NotificationPanel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationCount, setNotificationCount] = useState<NotificationCount>({
    total_unread: 0,
    by_category: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const groupNotificationsByDate = (notifications: Notification[]): GroupedNotifications[] => {
    const groups = notifications.reduce((acc: { [key: string]: Notification[] }, notification) => {
      const date = new Date(notification.created_at);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      let dateStr: string;
      if (date.toDateString() === today.toDateString()) {
        dateStr = 'Today';
      } else {
        dateStr = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
      }

      if (!acc[dateStr]) {
        acc[dateStr] = [];
      }
      acc[dateStr].push(notification);
      return acc;
    }, {});

    return Object.entries(groups)
      .map(([date, notifications]) => ({
        date,
        notifications: notifications.sort(
          (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        ),
      }))
      .sort((a, b) => {
        if (a.date === 'Today') return -1;
        if (b.date === 'Today') return 1;
        if (a.date === 'Yesterday') return -1;
        if (b.date === 'Yesterday') return 1;
        return new Date(b.notifications[0].created_at).getTime() - new Date(a.notifications[0].created_at).getTime();
      });
  };

  const fetchNotifications = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await axios.get<Notification[]>('api/user/notifications');
      setNotifications(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setNotifications([]);
      setError('Failed to load notifications');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNotificationCount = async () => {
    try {
      setError(null);
      const response = await axios.get<NotificationCount>('api/user/notifications/count');
      setNotificationCount(response.data);
    } catch (error) {
      console.error('Error fetching notification count:', error);
      setNotificationCount({ total_unread: 0, by_category: {} });
    }
  };

  const markNotificationsAsRead = async (notificationsToMark: Notification[]) => {
    try {
      const unreadIds = notificationsToMark.filter((n) => n.status === 'UNREAD').map((n) => n.id);

      if (unreadIds.length > 0) {
        await axios.post('api/user/notifications/mark-read', {
          notification_ids: unreadIds,
        });

        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) => ({
            ...notification,
            status: 'READ',
          })),
        );

        setNotificationCount((prev) => ({
          ...prev,
          total_unread: 0,
          by_category: Object.keys(prev.by_category).reduce(
            (acc, key) => ({
              ...acc,
              [key]: 0,
            }),
            {},
          ),
        }));
      }
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  useEffect(() => {
    fetchNotificationCount();
    const interval = setInterval(fetchNotificationCount, 30000);
    return () => clearInterval(interval);
  }, []);

  const handleNotificationClick = (notification: Notification) => {
    console.log('Clicked notification:', notification);
  };

  const handlePanelOpen = async () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);

    if (newIsOpen) {
      try {
        const response = await axios.get<Notification[]>('api/user/notifications');
        setNotifications(response.data);
        const unreadIds = response.data.filter((n) => n.status === 'UNREAD').map((n) => n.id);

        if (unreadIds.length > 0) {
          await axios.post('api/user/notifications/mark-read/', {
            notification_ids: unreadIds,
          });

          await fetchNotificationCount();
        }
      } catch (error) {
        console.error('Error in handlePanelOpen:', error);
      }
    }
  };

  return (
    <Box position="relative">
      <Box
        display="flex"
        padding="10px"
        alignItems="center"
        gap="10px"
        borderRadius="100px"
        background="var(--Neutrals-2, #F6F6F6)"
        cursor="pointer"
        onClick={handlePanelOpen}
      >
        <Image
          src={NotificationIcon}
          width="24px"
          height="24px"
          alt="notifications"
          onError={(e) => {
            e.currentTarget.style.display = 'none';
          }}
        />
        {notificationCount.total_unread > 0 && (
          <Box
            position="absolute"
            top="-1px"
            right="30px"
            bg="var(--red-5, #FF4D4F)"
            borderRadius="21px"
            minH="18px"
            minW="18px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="var(--Text-Secondary-default, #FFF)"
            fontWeight={500}
            fontSize="12px"
            padding="0 4px"
            whiteSpace="nowrap"
            boxShadow={'0px 1px 2px 0px rgba(0, 0, 0, 0.26)'}
          >
            {notificationCount.total_unread}
          </Box>
        )}
      </Box>

      {isOpen && (
        <Box
          position="absolute"
          top="100%"
          right="0"
          padding="24px"
          w="396px"
          bg="white"
          borderRadius="24px"
          boxShadow="0px 4px 20px rgba(0, 0, 0, 0.1)"
          mt="10px"
          zIndex={1000}
          maxH="649px"
          border="1px solid var(--Neutrals-4, #E9E9E9)"
        >
          <Text fs="20px" lineHeight="28px" textAlign={'left'}>
            Notifications
          </Text>

          {notificationCount.total_unread > 0 && (
            <Text fontSize="14px" color="gray.500" mt="4px">
              {notificationCount.total_unread} unread
            </Text>
          )}

          <Box mt="16px" overflow={'auto'} height={'500px'}>
            {isLoading ? (
              <Flex justify="center" align="center" h="100px">
                <Spinner color="blue.500" />
              </Flex>
            ) : error ? (
              <Text p={4} color="red.500" textAlign="center">
                {error}
              </Text>
            ) : notifications.length === 0 ? (
              <Text p={4} color="gray.500" textAlign="center">
                No notifications
              </Text>
            ) : (
              groupNotificationsByDate(notifications).map((group) => (
                <Box key={group.date} overflow={'auto'}>
                  <Flex justifyContent="center" alignItems="center" gap="10px">
                    <Box bg="rgba(0, 0, 0, 0.10)" width="100%" height="1px"></Box>
                    <Text fs="12px" c="var(--Text-Primary-description, #909090)" fw={500} lineHeight={'22px'}>
                      {group.date}
                    </Text>
                    <Box bg="rgba(0, 0, 0, 0.10)" width="100%" height="1px"></Box>
                  </Flex>
                  {group.notifications.map((notification) => (
                    <NotificationItem
                      key={notification.id}
                      notification={notification}
                      onClick={handleNotificationClick}
                    />
                  ))}
                </Box>
              ))
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NotificationPanel;
