import { Button, Flex, HStack, Image, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import LeftOutlined from '../assets/images/svgs/LeftOutlined.svg';
import RightOutlined from '../assets/images/svgs/RightOutlined.svg';

interface TablePaginationProps {
  currentPage: number;
  totalCount: number;
  entriesPerPage?: number;
  onPageChange: (page: number) => void;
  hasNext: boolean;
  hasPrevious: boolean;
}

type PageItem = number | '...';

const TablePagination: React.FC<TablePaginationProps> = ({
  currentPage = 1,
  totalCount,
  entriesPerPage = 10,
  onPageChange,
  hasNext,
  hasPrevious,
}) => {
  const totalPages = useMemo(() => Math.ceil(totalCount / entriesPerPage), [totalCount, entriesPerPage]);

  // Calculate start and end entries for current page
  const startEntry = (currentPage - 1) * entriesPerPage + 1;
  const endEntry = Math.min(currentPage * entriesPerPage, totalCount);
  const currentPageEntries = endEntry - startEntry + 1;

  const getPageNumbers = (): PageItem[] => {
    const delta = 2;
    const range: number[] = [];
    const rangeWithDots: PageItem[] = [];

    for (let i = Math.max(2, currentPage - delta); i <= Math.min(totalPages - 1, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage - delta > 2) {
      rangeWithDots.push(1, '...');
    } else {
      rangeWithDots.push(1);
    }

    rangeWithDots.push(...range);

    if (currentPage + delta < totalPages - 1) {
      rangeWithDots.push('...', totalPages);
    } else if (totalPages > 1) {
      rangeWithDots.push(totalPages);
    }

    return rangeWithDots;
  };

  const handlePageChange = (page: PageItem) => {
    if (typeof page === 'number') {
      onPageChange(page);
    }
  };

  return (
    <Flex justify="space-between" align="center" w="full">
      <Text
        color="var(--Text-Primary-description, #909090)"
        fontSize="14px"
        fontWeight="500"
        lineHeight="22px"
        fontFamily="VisbyCF"
      >
        Showing {currentPageEntries} entries per page of {totalCount} total entries
      </Text>

      <HStack spacing="1">
        <Button
          variant="ghost"
          size="sm"
          isDisabled={!hasPrevious}
          onClick={() => onPageChange(currentPage - 1)}
          border="none"
          bg="#F6F6F6"
          color="#909090"
          p={0}
          borderRadius={'12px'}
          minW="8"
          aria-label="Previous page"
        >
          <Image src={LeftOutlined} alt="Previous page" w="20px" h="20px" />
        </Button>

        {getPageNumbers().map((pageNum, idx) =>
          pageNum === '...' ? (
            <Text
              key={`dots-${idx}`}
              px="2"
              color="var(--Text-Primary-description, #909090)"
              fontSize="14px"
              fontWeight="500"
              fontFamily="VisbyCF"
            >
              ...
            </Text>
          ) : (
            <Button
              key={`page-${pageNum}`}
              variant={currentPage === pageNum ? 'solid' : 'ghost'}
              size="sm"
              onClick={() => handlePageChange(pageNum)}
              border="none"
              bg={currentPage === pageNum ? '#4096FF' : '#F6F6F6'}
              color={currentPage === pageNum ? '#FFF' : '#909090'}
              minW="32px"
              fontSize="14px"
              fontWeight="600"
              fontFamily="VisbyCF"
              h="32px"
              p="0px 10px"
              borderRadius="12px"
            >
              {pageNum}
            </Button>
          ),
        )}

        <Button
          variant="ghost"
          size="sm"
          isDisabled={!hasNext}
          border="none"
          bg="#F6F6F6"
          color="#909090"
          p={0}
          borderRadius={'12px'}
          onClick={() => onPageChange(currentPage + 1)}
          minW="8"
          aria-label="Next page"
        >
          <Image src={RightOutlined} alt="Next page" w="20px" h="20px" />
        </Button>
      </HStack>
    </Flex>
  );
};

export default TablePagination;
