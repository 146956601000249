import { Box } from '@chakra-ui/react';
import React from 'react';
import Text from './Text';
interface DaysFilterProps {
  onTimeRangeChange: (range: string) => void;
  activeTimeRange?: string;
}

const DaysFilter: React.FC<DaysFilterProps> = ({ onTimeRangeChange, activeTimeRange = 'all' }) => {
  const timeRanges = [
    { label: '24h', value: '24h' },
    { label: '7d', value: '7d' },
    { label: '30d', value: '30d' },
    { label: 'All', value: 'all' },
  ];

  return (
    <Box
      display="flex"
      padding="5px"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="14px"
      background="var(--Neutrals-2, #F6F6F6)"
    >
      {timeRanges.map(({ label, value }) => (
        <Box
          key={value}
          display="flex"
          padding="6px 12px"
          justifyContent="center"
          alignItems="center"
          gap="10px"
          flex="1 0 0"
          alignSelf="stretch"
          borderRadius="10px"
          boxShadow={activeTimeRange === value ? '0px 1px 3px 0px rgba(0, 0, 0, 0.19)' : 'none'}
          background={activeTimeRange === value ? '#FFF' : 'transparent'}
          cursor="pointer"
          onClick={() => onTimeRangeChange(value)}
          transition="background 0.2s"
          _hover={{
            background: activeTimeRange === value ? '#FFF' : 'rgba(255, 255, 255, 0.1)',
          }}
        >
          <Text
            fs="14px"
            fw={500}
            lineHeight={'22px'}
            c={
              activeTimeRange === value
                ? 'var(--Text-Primary-default, #000)'
                : 'var(--Text-Primary-description, #909090)'
            }
            style={{
              fontFamily: 'Poppins',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
            }}
          >
            {label}
          </Text>
        </Box>
      ))}
    </Box>
  );
};

export default DaysFilter;
