import { Box, Image, Input, InputGroup, Table, Tbody, Td, Th, Thead, Tr, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ExternalLink from '../../src/assets/images/svgs/ExternalLink.svg';
import OrangeDollar from '../../src/assets/images/svgs/OrangeDollar.svg';
import RefreshIcon from '../../src/assets/images/svgs/Refresh.svg';
import SearchIcon from '../../src/assets/images/svgs/SearchIcon.svg';
import axios from '../api/axios';
import PriceChart from '../components/Charts/PriceChart';
import VolumeChart from '../components/Charts/VolumeChart';
import ExchangeFilter from '../components/common/ExchangeFilter';
import Text from '../components/common/Text';
import { ChartConfig, ExchangeData, HolderData, VolumeData } from '../types/Types';
import TablePagination from './TablePagination';

const ITEMS_PER_PAGE = 20;

function Home() {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [exchangeData, setExchangeData] = useState<ExchangeData[]>([]);
  const [isLoadingHolders, setIsLoadingHolders] = useState(false);
  const [validDates, setValidDates] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [holderData, setHolderData] = useState<HolderData | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [exchangeType, setExchangeType] = useState('all');
  const [isLoading, setIsLoading] = useState(false);
  const [volumeData, setVolumeData] = useState<VolumeData | undefined>(undefined);
  const [hasError, setHasError] = useState(false);
  const toast = useToast();

  const fetchTokenData = async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const token = localStorage.getItem('access_token');

      if (!token) {
        toast({
          title: 'Authentication Error',
          description: 'Please login again',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setHasError(true);
        return;
      }

      // Use Promise.allSettled instead of Promise.all
      const results = await Promise.allSettled([
        axios.get('/api/user/token-historical-data/', {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get('/api/user/exchange-market-data/', {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get('/api/user/token-holder-history/', {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      // Check if all requests failed
      const allFailed = results.every((result) => result.status === 'rejected');
      if (allFailed) {
        setHasError(true);
        toast({
          title: 'Error fetching data',
          description: 'Unable to load token data. Please try again later.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      // Handle successful responses
      results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          switch (index) {
            case 0:
              setVolumeData(result.value.data);
              break;
            case 1:
              setExchangeData(result.value.data);
              break;
            case 2:
              setHolderData(result.value.data);
              break;
          }
        }
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      setHasError(true);
      toast({
        title: 'Error fetching data',
        description: 'Something went wrong while fetching data. Please try again.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTokenData();
  }, []);

  const filteredData = exchangeData.filter((exchange) => {
    const searchTermLower = searchTerm.toLowerCase().trim();
    const nameLower = exchange.exchange.name.toLowerCase();
    const pairLower = exchange.pair.toLowerCase();
    // Handle pair search with special characters
    const formattedSearchTerm = searchTermLower.replace(/[\/\s]/g, ''); // Remove slashes and spaces
    const formattedPair = pairLower.replace(/[\/\s]/g, ''); // Remove slashes and spaces from pair

    return (
      nameLower.includes(searchTermLower) || // Search in name
      pairLower === searchTermLower || // Exact match with pair (including /)
      formattedPair.includes(formattedSearchTerm) // Match without special characters
    );
  });
  const totalCount = filteredData.length;
  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
  const hasNext = currentPage < totalPages;
  const hasPrevious = currentPage > 1;

  // Get current page data from filtered results
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentData = filteredData.slice(startIndex, endIndex);

  // Add this handler for search input
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const fetchHolders = async (date?: Date) => {
    setIsLoadingHolders(true);
    try {
      let url = '/api/user/address-book/top/';
      if (date) {
        const formattedDate = date.toLocaleDateString('en-CA');
        url += `?date=${formattedDate}`;
      }

      const response = await fetch(url);
      const data = await response.json();
      // Handle the data as needed
    } catch (error) {
      console.error('Error fetching holders:', error);
      // Handle error appropriately
    } finally {
      setIsLoadingHolders(false);
    }
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      fetchHolders(date);
    } else {
      fetchHolders();
    }
  };

  const handleExchangeFilterChange = (type: string) => {
    setExchangeType(type);
  };

  const chartConfigs: ChartConfig[] = [
    {
      id: 'price',
      title: 'Daily Price',
      color: 'green',
      useRealData: true, // This will use API data
    },
    {
      id: 'holders',
      title: 'Token Holders',
      color: 'red',
      useRealData: true, // Changed to true since we're using real holder data
      isSecondChart: true,
    },
  ];
  return (
    <Box
      display="flex"
      padding="24px 24px 40px 24px"
      flexDirection="column"
      alignItems="flex-start"
      gap="36px"
      alignSelf="stretch"
    >
      {/* Header Section */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="flex-start"
        gap="16px"
        alignSelf="stretch"
        flexShrink={0}
      >
        <Text fs="36px" lineHeight={'24px'}>
          Track Your Token&apos;s Journey
        </Text>
        <Text fs="14px" fw={400} lineHeight={'22px'}>
          Access the latest price data, volume insights, and holder trends updated daily.
        </Text>
      </Box>

      {/* Charts Section */}
      <Box display="flex" alignItems="flex-start" gap="14px" alignSelf="stretch" flexShrink={0}>
        <Box display="flex" flexDirection="column" alignItems="flex-start" gap="14px" flex="1 0 0" alignSelf="stretch">
          {chartConfigs.map((config) => (
            <PriceChart key={config.id} config={config} priceData={volumeData} holderData={holderData} />
          ))}
        </Box>
        <Box display="flex" alignItems="flex-start" flex={'1 0 0'} gap="14px" alignSelf="stretch">
          <VolumeChart volumeData={volumeData} />
        </Box>
      </Box>

      {/* Table Container */}
      <Box
        display="flex"
        padding="24px"
        flexDirection="column"
        alignItems="center"
        maxHeight={'710px'}
        gap="24px"
        flex="1"
        overflow="hidden"
        // minHeight="260px"

        alignSelf="stretch"
        borderRadius="36px"
        border="1px solid rgba(255, 255, 255, 0.10)"
        background="#FFF"
        boxShadow="0px 1px 7px 0px rgba(0, 0, 0, 0.08)"
      >
        {/* Table Header */}
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center" gap="6px" color="var(--Text-Primary-default, #000)">
            <Text fs="20px" lineHeight={'28px'}>
              Exchange Prices
            </Text>
          </Box>
          <Box display="flex" width="767.5px" justifyContent="flex-end" alignItems="center" gap="12px">
            {/* <DatePicker onDateChange={handleDateChange} selectedDate={selectedDate} validDates={validDates} /> */}
            <ExchangeFilter activeFilter={exchangeType} onFilterChange={handleExchangeFilterChange} />
            <InputGroup
              width="fit-content"
              padding="0px 20px 0px 12px"
              gap="10px"
              border="0.5px solid var(--Neutrals-4, #E9E9E9)"
              borderRadius="16px"
              background="var(--Neutrals-2, #F6F6F6)"
              alignItems="center"
            >
              <Image src={SearchIcon} alt="search" cursor="pointer" />
              <Input
                border="none"
                color="#000"
                padding={0}
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Search by Exchange or pairs"
                background="transparent"
                _placeholder={{
                  color: 'var(--Text-Primary-description, #909090)',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
                _focus={{
                  border: 'none',
                  boxShadow: 'none',
                }}
              />
            </InputGroup>
          </Box>
        </Box>

        {/* Table Content Container */}
        <Box display="flex" flexDirection="column" flex="1" width="100%" overflow="hidden">
          {/* Fixed Header */}
          <Box flexShrink={0}>
            <Table variant="unstyled" display={['none', 'none', 'none', 'table']}>
              <Thead>
                <Tr borderRadius="10px" background="#F6F6F6" display="flex" justifyContent="space-between">
                  <Th
                    border="none"
                    fontFamily="VisbyCF"
                    color="#1A1A1A"
                    fontSize="13px"
                    fontWeight="600"
                    lineHeight="20px"
                    padding="11px"
                    flex="1"
                  >
                    Exchange
                  </Th>
                  <Th
                    border="none"
                    fontFamily="VisbyCF"
                    color="#1A1A1A"
                    fontSize="13px"
                    fontWeight="600"
                    lineHeight="20px"
                    padding="11px"
                    flex="1"
                  >
                    Price
                  </Th>
                  <Th
                    border="none"
                    fontFamily="VisbyCF"
                    color="#1A1A1A"
                    fontSize="13px"
                    fontWeight="600"
                    lineHeight="20px"
                    padding="11px"
                    flex="1"
                  >
                    Pair
                  </Th>
                  <Th
                    border="none"
                    fontFamily="VisbyCF"
                    color="#1A1A1A"
                    fontSize="13px"
                    fontWeight="600"
                    lineHeight="20px"
                    padding="11px"
                    flex="1"
                  >
                    Last Updated
                  </Th>
                </Tr>
              </Thead>
            </Table>
          </Box>

          {/* Scrollable Table Body */}
          <Box
            flex="1"
            overflowY="auto"
            sx={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                borderRadius: '2px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '2px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555',
              },
            }}
          >
            <Table variant="unstyled" display={['none', 'none', 'none', 'table']}>
              <Tbody>
                {currentData.map((exchange) => (
                  <Tr
                    key={exchange.id}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    borderBottom="1px solid var(--Neutrals-4, #E9E9E9)"
                  >
                    <Td
                      display="flex"
                      height="48px"
                      p="0px 12px"
                      alignItems="center"
                      gap="8px"
                      flex="1"
                      onClick={() => window.open('https://coinmarketcap.com/currencies/bitcoin/#Markets', '_blank')}
                    >
                      <Image src={exchange.exchange.logo} alt={exchange.exchange.name} width="24px" height="24px" />
                      <Text
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="22px"
                        fontFamily="VisbyCF"
                        color="var(--Text-Primary-default, #000)"
                      >
                        {exchange.exchange.name}
                      </Text>
                    </Td>
                    <Td display="flex" height="48px" p="0px 12px" alignItems="center" gap="8px" flex="1">
                      <Image src={OrangeDollar} />
                      <Text
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="22px"
                        fontFamily="VisbyCF"
                        color="var(--Text-Primary-default, #000)"
                      >
                        {exchange.price_formatted}
                      </Text>
                    </Td>
                    <Td display="flex" height="48px" p="0px 12px" alignItems="center" gap="8px" flex="1">
                      <Text fs="14px" c="var(--Text-action, #4096FF)" fw={500} lineHeight="22px">
                        {exchange.pair}
                      </Text>
                      <Image src={ExternalLink} />
                    </Td>
                    <Td display="flex" height="48px" p="0px 12px" alignItems="center" gap="8px" flex="1">
                      <Image src={RefreshIcon} />
                      <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={500} lineHeight="22px">
                        {new Date(exchange.updated_at).toLocaleString()}
                      </Text>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>

        {/* Pagination */}

        <TablePagination
          currentPage={currentPage}
          totalCount={totalCount}
          entriesPerPage={ITEMS_PER_PAGE}
          onPageChange={handlePageChange}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      </Box>
    </Box>
  );
}

export default Home;
