import { Box, Button, Flex, Image, Input, InputGroup, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import Emptydata from '../../src/assets/images/svgs/Emptydata.svg';
import EmptydataVol from '../../src/assets/images/svgs/Emptydatavol.svg';
import SearchIcon from '../../src/assets/images/svgs/SearchIcon.svg';
import Telegram from '../../src/assets/images/svgs/Telegram.svg';
import Info from '../assets/images/svgs/Info.svg';
import Text from '../components/common/Text';
import DatePicker from '../components/common/DatePicker';
import ExchangeFilter from '../components/common/ExchangeFilter';
import LeftOutlined from '../assets/images/svgs/LeftOutlined.svg';
import RightOutlined from '../assets/images/svgs/RightOutlined.svg';

const HomeEmpty = () => {
  // Sample data for demonstration
  const sampleData = [
    {
      id: 1,
      exchange: { name: 'Coinbase Exchange', logo: '/api/placeholder/24/24' },
      price_formatted: '$398.00',
      pair: 'BTC/USD',
      updated_at: '2024-01-01T17:44:17',
    },
    {
      id: 2,
      exchange: { name: 'Binance', logo: '/api/placeholder/24/24' },
      price_formatted: '$397.89',
      pair: 'BTC/USDT',
      updated_at: '2024-01-01T17:44:15',
    },
  ];

  return (
    <Box
      display="flex"
      padding="24px 24px 40px 24px"
      flexDirection="column"
      alignItems="flex-start"
      gap="36px"
      alignSelf="stretch"
    >
      {/* Header Section */}
      <Box
        display="flex"
        padding="24px"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="flex-start"
        gap="16px"
        alignSelf="stretch"
        borderRadius="24px"
        border="1px solid rgba(255, 255, 255, 0.10)"
        background="#2B2B2B"
      >
        <Flex display="flex" flexDirection="column" alignItems="flex-start" gap="10px" alignSelf="stretch">
          <Text c=" var(--Text-Secondary-default, #FFF)" fs="34px" lineHeight="44px" fw={600} textAlign={'left'}>
            This token isn’t listed on CoinMarketCap.
            <br />
             Let’s help get you started!
          </Text>
          <Text fs="14px" fw={400} lineHeight="22px" c=" var(--Text-Secondary-default, #FFF)">
            Connect with our team on Telegram for assistance in listing your token on CMC.
          </Text>
        </Flex>

        <Box
          display="flex"
          padding="6px var(--Button-paddingContentHorizontal, 16px)"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap="8px"
          borderRadius="16px"
          border=" 1px solid #2D8DFB"
          background="#2D8DFB"
          cursor={'pointer'}
        >
          <Image src={Telegram} alt="telegram" />
          <Text c="var(--Button-colorTextLightSolid, #FFF)" fs="14px" fw={500} lineHeight={'22px'}>
            Contact Support
          </Text>
        </Box>
      </Box>

      {/* Charts Section */}
      <Box display="flex" alignItems="flex-start" gap="14px" alignSelf="stretch" flexShrink={0}>
        <Box display="flex" flexDirection="column" alignItems="flex-start" gap="14px" flex="1 0 0" alignSelf="stretch">
          <Box
            display="flex"
            minHeight="184px"
            padding="24px"
            alignItems="flex-start"
            alignContent="flex-start"
            gap="16px"
            alignSelf="stretch"
            flexWrap="wrap"
            borderRadius="36px"
            border="1px solid rgba(255, 255, 255, 0.10)"
            background="var(--Neutrals-1, #FFF)"
            boxShadow="0px 1px 7px 0px rgba(0, 0, 0, 0.08)"
          >
            <Flex
              display="flex"
              height="136px"
              minWidth="240px"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              gap="auto"
              flex="1 0 0"
            >
              <Flex alignItems="center" gap="8px">
                <Text fontSize="16px" fontWeight="600" color="black">
                  Daily Price
                </Text>
              </Flex>

              {/* Price and Update Info */}
              <Flex
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
                gap="8px"
                alignSelf="stretch"
              >
                <Text fontSize="42px" fontWeight="600" letterSpacing="-1.2px" lineHeight="52px">
                  $0
                </Text>

                <Flex display="flex" alignItems="center" gap="8px">
                  <Image src={Info} width="16px" height="16px" />
                  <Text color="var(--Neutrals-6, #909090)" fontSize="14px" fontWeight="500">
                    Daily price is unavailable for this token
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Box>
          <Box
            display="flex"
            minHeight="184px"
            padding="24px"
            alignItems="flex-start"
            alignContent="flex-start"
            gap="16px"
            alignSelf="stretch"
            flexWrap="wrap"
            borderRadius="36px"
            border="1px solid rgba(255, 255, 255, 0.10)"
            background="var(--Neutrals-1, #FFF)"
            boxShadow="0px 1px 7px 0px rgba(0, 0, 0, 0.08)"
          >
            <Flex
              display="flex"
              height="136px"
              minWidth="240px"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              gap="auto"
              flex="1 0 0"
            >
              <Flex alignItems="center" gap="8px">
                <Text fontSize="16px" fontWeight="600" color="black">
                  Token Holder
                </Text>
              </Flex>

              {/* Price and Update Info */}
              <Flex
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
                gap="8px"
                alignSelf="stretch"
              >
                <Text fontSize="42px" fontWeight="600" letterSpacing="-1.2px" lineHeight="52px">
                  0
                </Text>

                <Flex display="flex" alignItems="center" gap="8px">
                  <Image src={Info} width="16px" height="16px" />
                  <Text color="var(--Neutrals-6, #909090)" fontSize="14px" fontWeight="500">
                    Token Holders amount is unavailable for this token
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Box>
        <Box display="flex" alignItems="flex-start" flex="1 0 0" gap="14px" alignSelf="stretch">
          <Box
            display="flex"
            padding="24px"
            flexDirection="column"
            gap="8px"
            flex="1 0 0"
            height="100%"
            borderRadius="36px"
            border="1px solid rgba(255, 255, 255, 0.10)"
            background="var(--Neutrals-1, #FFF)"
            boxShadow="0px 1px 7px 0px rgba(0, 0, 0, 0.08)"
            justifyContent="center"
            alignItems="center"
          >
            <Flex alignItems="center" gap="8px" alignSelf="stretch">
              <Image src={Info} alt="info" />
              <Text fontSize="sm" fontWeight="bold" color="gray.500">
                Daily Volume
              </Text>

              <Flex
                display="flex"
                padding="24px 0px"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap="14px"
                flex="1 0 0"
              >
                <Image src={EmptydataVol} alt="emptydata" width={'109px'} height={'88px'} />
                <Text fs="14px" fw={500} lineHeight={'22px'}>
                  Daily Volume is unavailable for this token
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>

      {/* Table Container */}
      <Box
        display="flex"
        padding="24px"
        flexDirection="column"
        alignItems="center"
        maxHeight={'710px'}
        gap="24px"
        flex="1"
        overflow="hidden"
        // minHeight="260px"

        alignSelf="stretch"
        borderRadius="36px"
        border="1px solid rgba(255, 255, 255, 0.10)"
        background="#FFF"
        boxShadow="0px 1px 7px 0px rgba(0, 0, 0, 0.08)"
      >
        {/* Table Header */}
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center" gap="6px" color="var(--Text-Primary-default, #000)">
            <Text fs="20px" lineHeight={'28px'}>
              Exchange Prices
            </Text>
          </Box>

          <Box display="flex" width="fit-content" justifyContent="flex-end" alignItems="center" gap="12px">
            <DatePicker />
            <ExchangeFilter />
            <InputGroup
              width="fit-content"
              padding="0px 20px 0px 12px"
              gap="10px"
              border="0.5px solid var(--Neutrals-4, #E9E9E9)"
              borderRadius="16px"
              background="var(--Neutrals-2, #F6F6F6)"
              alignItems="center"
            >
              <Image src={SearchIcon} alt="search" cursor="pointer" />
              <Input
                border="none"
                color="#000"
                padding={0}
                placeholder="Search by Exchange or pairs"
                background="transparent"
                _placeholder={{
                  color: 'var(--Text-Primary-description, #909090)',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
                _focus={{
                  border: 'none',
                  boxShadow: 'none',
                }}
              />
            </InputGroup>
          </Box>
        </Box>

        {/* Table Content Container */}
        <Box display="flex" flexDirection="column" flex="1" width="100%" overflow="hidden">
          {/* Fixed Header */}
          <Box flexShrink={0}>
            <Table variant="unstyled" display={['none', 'none', 'none', 'table']}>
              <Thead>
                <Tr borderRadius="10px" background="#F6F6F6" display="flex" justifyContent="space-between">
                  <Th
                    border="none"
                    fontFamily="VisbyCF"
                    color="#1A1A1A"
                    fontSize="13px"
                    fontWeight="600"
                    lineHeight="20px"
                    padding="11px"
                    flex="1"
                  >
                    Exchange
                  </Th>
                  <Th
                    border="none"
                    fontFamily="VisbyCF"
                    color="#1A1A1A"
                    fontSize="13px"
                    fontWeight="600"
                    lineHeight="20px"
                    padding="11px"
                    flex="1"
                  >
                    Price
                  </Th>
                  <Th
                    border="none"
                    fontFamily="VisbyCF"
                    color="#1A1A1A"
                    fontSize="13px"
                    fontWeight="600"
                    lineHeight="20px"
                    padding="11px"
                    flex="1"
                  >
                    Pair
                  </Th>
                  <Th
                    border="none"
                    fontFamily="VisbyCF"
                    color="#1A1A1A"
                    fontSize="13px"
                    fontWeight="600"
                    lineHeight="20px"
                    padding="11px"
                    flex="1"
                  >
                    Last Updated
                  </Th>
                </Tr>
              </Thead>
            </Table>
          </Box>

          {/* Scrollable Table Body */}
          <Box
            flex="1"
            overflowY="auto"
            sx={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                borderRadius: '2px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '2px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555',
              },
            }}
          >
            <Table variant="unstyled" display={['none', 'none', 'none', 'table']}>
              <Tbody>
                <Box
                  display="flex"
                  padding="56px 0"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gap="14px"
                >
                  <Image src={Emptydata} alt="empty search" />
                  <Box gap={'8px'}>
                    <Text fw={500} lineHeight={'22px'}>
                      Exchange data unavailable
                    </Text>
                    <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={500} lineHeight={'22px'}>
                      Contact our Support to help you get listed!
                    </Text>
                  </Box>
                  <Box
                    display="flex"
                    padding="6px var(--Button-paddingContentHorizontal, 16px)"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    gap="8px"
                    borderRadius="16px"
                    border="1px solid #2D8DFB"
                    background="#2D8DFB"
                    cursor={'pointer'}
                  >
                    <Image src={Telegram} alt="telegram" />
                    <Text c="var(--Button-colorTextLightSolid, #FFF)" fs="14px" fw={500} lineHeight={'22px'}>
                      Contact Support
                    </Text>
                  </Box>
                </Box>
              </Tbody>
            </Table>
            <Flex width={'100%'} justifyContent={'flex-end'} gap={'8px'}>
              <Button
                variant="ghost"
                size="sm"
                border="none"
                bg="#F6F6F6"
                color="#909090"
                p={0}
                borderRadius={'12px'}
                minW="8"
                aria-label="Previous page"
              >
                <Image src={LeftOutlined} alt="Previous page" w="20px" h="20px" />
              </Button>

              <Button
                size="sm"
                border="none"
                bg="#4096FF"
                color="#FFF"
                minW="32px"
                fontSize="14px"
                fontWeight="600"
                fontFamily="VisbyCF"
                h="32px"
                p="0px 10px"
                borderRadius="12px"
              >
                1
              </Button>
              <Button
                variant="ghost"
                size="sm"
                border="none"
                bg="#F6F6F6"
                color="#909090"
                p={0}
                borderRadius={'12px'}
                minW="8"
                aria-label="Previous page"
              >
                <Image src={RightOutlined} alt="Previous page" w="20px" h="20px" />
              </Button>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeEmpty;
