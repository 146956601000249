import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const BalanceIcon = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 21 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7425 2.93295H5.42585C3.22585 2.93295 1.75919 4.03295 1.75919 6.59961V10.8913C1.75919 12.7413 2.51752 13.8246 3.76752 14.2913C3.95085 14.3579 4.15085 14.4163 4.35919 14.4496C4.69252 14.5246 5.05085 14.5579 5.42585 14.5579H12.7509C14.9509 14.5579 16.4175 13.4579 16.4175 10.8913V6.59961C16.4175 6.20795 16.3842 5.85794 16.3092 5.53295C15.9509 3.72461 14.6092 2.93295 12.7425 2.93295ZM4.31752 10.6246C3.97585 10.6246 3.69252 10.3413 3.69252 9.99961V7.49961C3.69252 7.15795 3.97585 6.87461 4.31752 6.87461C4.65919 6.87461 4.94252 7.15795 4.94252 7.49961V9.99961C4.94252 10.3413 4.65919 10.6246 4.31752 10.6246ZM6.88419 8.74961C6.88419 7.53295 7.86752 6.54961 9.08419 6.54961C10.3009 6.54961 11.2842 7.53295 11.2842 8.74961C11.2842 9.96628 10.3009 10.9496 9.08419 10.9496C7.86752 10.9496 6.88419 9.96628 6.88419 8.74961ZM13.8425 10.6246C13.5009 10.6246 13.2175 10.3413 13.2175 9.99961V7.49961C13.2175 7.15795 13.5009 6.87461 13.8425 6.87461C14.1842 6.87461 14.4675 7.15795 14.4675 7.49961V9.99961C14.4675 10.3413 14.1842 10.6246 13.8425 10.6246Z"
    />
    <path d="M18.9182 13.3903C18.9182 15.9569 17.4515 17.0653 15.2432 17.0653H7.92651C7.30151 17.0653 6.74318 16.9736 6.25984 16.7903C5.86818 16.6486 5.52651 16.4403 5.25151 16.1736C5.10151 16.0319 5.21818 15.8069 5.42651 15.8069H12.7432C15.8265 15.8069 17.6598 13.9736 17.6598 10.8986V6.5986C17.6598 6.3986 17.8848 6.2736 18.0265 6.4236C18.5932 7.0236 18.9182 7.8986 18.9182 9.0986V13.3903Z" />
  </Icon>
);
