import { Box } from '@chakra-ui/react';
import React from 'react';

interface CircularProgressProps {
  percentage?: number;
}

const CircularProgress: React.FC<CircularProgressProps> = ({ percentage = 0 }) => {
  const normalizedPercentage = Math.min(100, Math.max(0, percentage));
  const radius = 17;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (normalizedPercentage / 100) * circumference;

  return (
    <Box width="45px" height="45px" position="relative">
      <svg viewBox="0 0 40 40" style={{ transform: 'rotate(-90deg)' }}>
        <circle cx="20" cy="20" r="17" stroke="#E8E8E8" strokeWidth="3" fill="none" />
        <circle
          cx="20"
          cy="20"
          r="17"
          stroke="#0066FF"
          strokeWidth="3"
          fill="none"
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          style={{
            transition: 'stroke-dashoffset 0.3s ease-in-out',
          }}
        />
      </svg>
      {/* Percentage text */}
      <Box
        position="absolute"
        top="30%"
        left="35%"
        transform="translate(-50%, -50%)"
        fontSize="10px"
        fontWeight="bold"
        fontFamily="Poppins"
        color="#000"
        style={{ transform: 'rotate(0deg)' }}
      >
        {normalizedPercentage}%
      </Box>
    </Box>
  );
};

export default CircularProgress;
