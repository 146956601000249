import { Box } from '@chakra-ui/react';
import React from 'react';

interface ExchangeTypeFilterProps {
  activeFilter?: string;
  onFilterChange?: (type: string) => void;
}

const ExchangeFilter: React.FC<ExchangeTypeFilterProps> = ({ activeFilter = 'all', onFilterChange }) => {
  const filterTypes = [
    { label: 'All', value: 'all' },
    { label: 'CEX', value: 'cex' },
    { label: 'DEX', value: 'dex' },
  ];

  const handleFilterChange = (value: string) => {
    if (onFilterChange) {
      onFilterChange(value);
    }
  };

  return (
    <Box
      display="flex"
      p="5px"
      alignItems="center"
      gap="5px"
      borderRadius="14px"
      background="var(--Neutrals-2, #F6F6F6)"
    >
      {filterTypes.map(({ label, value }) => (
        <Box
          key={value}
          display="flex"
          p="6px 12px"
          justifyContent="center"
          alignItems="center"
          gap="10px"
          borderRadius="10px"
          boxShadow={activeFilter === value ? '0px 1px 3px 0px rgba(0, 0, 0, 0.19)' : 'none'}
          background={activeFilter === value ? '#FFF' : 'transparent'}
          cursor="pointer"
          onClick={() => handleFilterChange(value)}
          transition="background 0.2s"
          _hover={{
            background: activeFilter === value ? '#FFF' : 'rgba(255, 255, 255, 0.1)',
          }}
        >
          <div
            style={{
              color:
                activeFilter === value
                  ? 'var(--Text-Primary-default, #000)'
                  : 'var(--Text-Primary-description, #909090)',
              textAlign: 'center',
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px',
            }}
          >
            {label}
          </div>
        </Box>
      ))}
    </Box>
  );
};

export default ExchangeFilter;
