import React from 'react';

interface InputFieldProps {
  label: string;
  placeholder: string;
  value: string | number;
  onChange: (value: string) => void;
}

const InputField: React.FC<InputFieldProps> = ({ label, placeholder, value, onChange }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '4px',
        alignSelf: 'stretch',
      }}
    >
      <span
        style={{
          color: 'var(--Text-Primary-description, #909090)',
          fontFamily: 'Poppins',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '16px',
        }}
      >
        {label}
      </span>
      <input
        type="text"
        placeholder={placeholder}
        value={value.toString()}
        onChange={(e) => onChange(e.target.value)}
        style={{
          color: 'var(--Neutrals-6, #909090)',
          fontFamily: 'Poppins',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          padding: '10px 12px',
          gap: '10px',
          lineHeight: '24px',
          border: '0.5px solid var(--Neutrals-4, #E9E9E9)',
          outline: 'none',
          background: 'var(--Neutrals-2, #F6F6F6)',
          flex: 1,
          borderRadius: '16px',
          width: '100%',
        }}
      />
    </div>
  );
};

export default InputField;
